import * as types from '../../actions/types/patrimonio/inversiones';

const defaultInversion = {
  id: null,
  tipo: '',
  cuenta_contrato: '',
  institucion: '',
  titular: '',
  indicaciones: '',
  beneficiarios: []
};

const defaultBeneficiario = {
  nombre_completo: '',
  porcentaje: ''
};

const initialState = {
  inversion: {...defaultInversion},
  beneficiario: {...defaultBeneficiario},
  inversiones: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let inversiones = [];

  switch (action.type) {
    case types.GET_INVERSIONES_INIT:
      return {
        ...state,
        inversion: {...defaultInversion},
        beneficiario: {...defaultBeneficiario},
        inversiones: [],
        isGetting: true,
        errors: null
      };
    case types.GET_INVERSIONES_DONE:
      return {
        ...state,
        inversiones: [...action.inversiones],
        isGetting: false
      };
    case types.GET_INVERSIONES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_INVERSION_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.ADD_BENEFICIARIO:
      return {
        ...state,
        inversion: {
          ...state.inversion,
          beneficiarios: [
            ...state.inversion.beneficiarios,
            action.beneficiario
          ]
        }
      };
    case types.RESET_BENEFICIARIO:
      return {
        ...state,
        beneficiario: {...defaultBeneficiario}
      };
    case types.REMOVE_BENEFICIARIO:
      const beneficiarios = [...state.inversion.beneficiarios];
      beneficiarios.splice(action.index, 1);

      return {
        ...state,
        inversion: {
          ...state.inversion,
          beneficiarios
        }
      };
    case types.RESET_INVERSION_INPUTS:
      return {
        ...state,
        inversion: {...defaultInversion},
        beneficiario: {...defaultBeneficiario},
        errors: null
      };
    case types.SAVE_INVERSION_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_INVERSION_DONE:
      return {
        ...state,
        inversion: {...defaultInversion},
        beneficiario: {...defaultBeneficiario},
        inversiones: [
          ...state.inversiones,
          action.inversion
        ],
        isSaving: false
      };
    case types.SAVE_INVERSION_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_INVERSION_INPUTS:
      return {
        ...state,
        inversion: {...action.inversion}
      };
    case types.UPDATE_INVERSION_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_INVERSION_DONE:
      inversiones = state.inversiones.map((inversion) => {
        if (inversion.id === action.inversion.id) {
          return action.inversion;
        }

        return inversion;
      });

      return {
        ...state,
        inversion: {...defaultInversion},
        beneficiario: {...defaultBeneficiario},
        inversiones,
        isUpdating: false
      };
    case types.UPDATE_INVERSION_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_INVERSION_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_INVERSION_DONE:
      inversiones = state.inversiones.filter((inversion) => inversion.id !== action.id);

      return {
        ...state,
        inversiones,
        isDeleting: false
      };
    case types.DELETE_INVERSION_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
