import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Timeline.module.scss';

const Context = React.createContext({});

const Timeline = (props) => {
  const [activePoint, setActivePoint] = React.useState('');

  const onSelectPoint = (point) => {
    setActivePoint(point);

    props.onActivePoint(point);
  };

  React.useEffect(() => {
    onSelectPoint(props.defaultActivePoint);

    // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider value={{ activePoint, onSelectPoint }}>
      <div className={styles.Timeline}>
        {props.children}
      </div>
    </Context.Provider>
  );
};

Timeline.defaultProps = {
  defaultActivePoint: '0',
  onActivePoint: () => {}
};

Timeline.propTypes = {
  defaultActivePoint: PropTypes.string,
  onSelectedPoint: PropTypes.func
};

const Block = (props) => {
  const { onSelectPoint } = React.useContext(Context);

  return (
    <div className={styles.Block} onClick={() => onSelectPoint(props.activePoint)}>
      {props.children}
    </div>
  );
};

const Point = (props) => {
  const { activePoint } = React.useContext(Context);

  const pointStyle = classNames(styles.Point, {
    [styles.isActive]: (props.activePoint === activePoint)
  });

  return (
    <div className={pointStyle}>
      <div className={styles.Circle}>
        {props.children({ viewBox: '0 0 512 512', height: 40, className: styles.Icon })}
      </div>
    </div>
  );
};

Point.defaultProps = {
  activePoint: '-1'
};

Point.propTypes = {
  activePoint: PropTypes.string
};

const Content = (props) => (
  <div className={styles.Content}>
    {props.children}
  </div>
);

const Title = (props) => (
  <h3 className={styles.Title}>{props.children}</h3>
);

const Paragraph = (props) => (
  <p className={styles.Paragraph}>{props.children}</p>
);

Timeline.Block = Block;
Timeline.Point = Point;
Timeline.Content = Content;
Timeline.Title = Title;
Timeline.Paragraph = Paragraph;

export default Timeline;
