import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import scriptLoader from 'react-async-script-loader';
import Form from 'react-bootstrap/Form';
import Button from '../../UI/Button/Button';
import IconOxxo from '../../UI/Icons/IconOxxo/IconOxxo';
import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import styles from './Oxxo.module.scss';

const Oxxo = (props) => {
  const history = useHistory();
  const mercadoPago = React.useRef(null);
  const ticket = useSelector((state) => state.auth.ticket);
  const isPayingWithOxxo = useSelector((state) => state.auth.isPayingWithOxxo);
  const isPaidWithOxxo = useSelector((state) => state.auth.isPaidWithOxxo);
  const dispatch = useDispatch();

  const onPay = (event) => {
    event.preventDefault();

    mercadoPago.current.createToken(event.target, async (status, response) => {
      if (status !== 200 && status !== 201) {
        console.log('verify filled data');
      } else {
        dispatch(actions.oxxoPayOrder({ ...props.registeredUser, plan_id: props.plan }));
      }
    });
  };

  React.useEffect(() => {
    if (isPaidWithOxxo) {
      dispatch(actions.resetRegisteredUser());
      history.push('/registrarse');
    }

    // eslint-disable-next-line
  }, [isPaidWithOxxo]);

  React.useEffect(() => {
    if (!helpers.isEmptyString(ticket)) {
      window.open(ticket, '_blank');
    }
  }, [ticket]);

  React.useEffect(() => {
    const { isScriptLoaded, isScriptLoadSucceed } = props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      mercadoPago.current = window.Mercadopago;
      mercadoPago.current.setPublishableKey('TEST-e482872b-4910-4a01-b677-10df65dd0701');
    }

    // eslint-disable-next-line
  }, [props.isScriptLoaded, props.isScriptLoadSucceed]);

  return (
    <Form className={styles.Form} onSubmit={onPay}>
      <input type="hidden" name="paymentMethodId" value="oxxo" />
      <Button
        type="submit"
        variant="secondary"
        className={styles.Button}
        disabled={isPayingWithOxxo}
      >
        {isPayingWithOxxo ? 'Generando referencia de pago…' : (<>Pagar en <IconOxxo viewBox="0 0 956.69287 519.80316" height="34" /></>)}
      </Button>
    </Form>
  );
};

Oxxo.propTypes = {
  plan: PropTypes.number,
  registeredUser: PropTypes.object
};

export default scriptLoader('https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js')(Oxxo);
