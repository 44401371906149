export const GET_INSTRUCCIONES_PARTICULARES_INIT = 'GET_INSTRUCCIONES_PARTICULARES_INIT';
export const GET_INSTRUCCIONES_PARTICULARES_DONE = 'GET_INSTRUCCIONES_PARTICULARES_DONE';
export const GET_INSTRUCCIONES_PARTICULARES_FAIL = 'GET_INSTRUCCIONES_PARTICULARES_FAIL';

export const CHANGE_INSTRUCCIONES_PARTICULARES_INPUT = 'CHANGE_INSTRUCCIONES_PARTICULARES_INPUT';

export const SAVE_INSTRUCCIONES_PARTICULARES_INIT = 'SAVE_INSTRUCCIONES_PARTICULARES_INIT';
export const SAVE_INSTRUCCIONES_PARTICULARES_DONE = 'SAVE_INSTRUCCIONES_PARTICULARES_DONE';
export const SAVE_INSTRUCCIONES_PARTICULARES_FAIL = 'SAVE_INSTRUCCIONES_PARTICULARES_FAIL';

export const DOWNLOAD_AUDIO_INIT = 'DOWNLOAD_AUDIO_INIT';
export const DOWNLOAD_AUDIO_DONE = 'DOWNLOAD_AUDIO_DONE';
export const DOWNLOAD_AUDIO_FAIL = 'DOWNLOAD_AUDIO_FAIL';

export const DOWNLOAD_VIDEO_INIT = 'DOWNLOAD_VIDEO_INIT';
export const DOWNLOAD_VIDEO_DONE = 'DOWNLOAD_VIDEO_DONE';
export const DOWNLOAD_VIDEO_FAIL = 'DOWNLOAD_VIDEO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
