import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Background from '../../UI/Landing/Backgroud/Background';
import Title from '../Title/Title';
import Plans from '../../UI/Landing/Plans/Plans';
import PlanFree from './PlanFree/PlanFree';
import PlanBimestral from './PlanBimestral/PlanBimestral';
import PlanAnnual from './PlanAnual/PlanAual';

const Planes = () => (
  <Background>
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              <Title center quote="Por si no te vuelvo a ver" text="Planes" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Plans>
                <PlanFree />
                <PlanAnnual />
                <PlanBimestral />
              </Plans>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Background>
);

export default Planes;
