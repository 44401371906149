import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TerminosCondiciones from '../TerminosCondiciones/TerminosCodiciones';
import PoliticaPrivacidad from '../PoliticaPrivacidad/PoliticaPrivacidad';
import styles from './Copyright.module.scss';

const Copyright = (props) => {
  const [showTerminosCondiciones, setShowTerminosCondiciones] = React.useState(false);
  const [showPoliticaPrivacidad, setShowPoliticaPrivacidad] = React.useState(false);

  const onShowTerminosCondiciones = (event) => {
    event.preventDefault();

    setShowTerminosCondiciones(true);
  };
  
  const onHideTerminosCondiciones = () => setShowTerminosCondiciones(false);

  const onShowPoliticaPrivacidad = (event) => {
    event.preventDefault();

    setShowPoliticaPrivacidad(true);
  };

  const onHidePoliticaPrivacidad = () => setShowPoliticaPrivacidad(false);

  return (
    <React.Fragment>
      <Container fluid className={classNames(styles.Container, props.className)}>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col>
                  <div className={styles.Copyright}>
                    &copy;2020 Por si no te vuelvo a ver. Todos los derechos reservados. <a href="!#" className={styles.Link} onClick={onShowPoliticaPrivacidad}>Aviso de privacidad</a>. <a href="!#" className={styles.Link} onClick={onShowTerminosCondiciones}>Términos y condiciones</a>.
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <TerminosCondiciones show={showTerminosCondiciones} onHide={onHideTerminosCondiciones} />
      <PoliticaPrivacidad show={showPoliticaPrivacidad} onHide={onHidePoliticaPrivacidad} />
    </React.Fragment>
  );
};

Copyright.propTypes = {
  className: PropTypes.string
};

export default Copyright;
