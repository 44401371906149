import * as types from '../../actions/types/ultimos-deseos/restos';

const defaultSepultado = {
  nombre_nicho: '',
  telefono: '',
  colonia: '',
  ciudad: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  contrato_digital: '',
  contrato_fisico: '',
  seccion_lote_bloque: '',
  apitafio: '',
  observaciones: '',
  has_contrato: true
};

const defaultCremado = {
  responsable: '',
  telefono: '',
  colonia: '',
  ciudad: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  documento_digital: '',
  documento_fisico: '',
  lugar_numero: '',
  indicaciones: '',
  has_nicho: true
};

const defaultResto = {
  is_cremado: false,
  sepultado: {...defaultSepultado},
  cremado: {...defaultCremado}
};

const initialState = {
  resto: {...defaultResto},
  isGetting: false,
  isSaving: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESTOS_INIT:
      return {
        ...state,
        resto: {...defaultResto},
        isGetting: true,
        errors: null
      };
    case types.GET_RESTOS_DONE:
      return {
        ...state,
        resto: {
          ...state.resto,
          ...action.resto,
          sepultado: {
            ...state.resto.sepultado,
            ...action.resto.sepultado
          },
          cremado: {
            ...state.resto.cremado,
            ...action.resto.cremado
          }
        },
        isGetting: false
      };
    case types.GET_RESTOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_RESTO_INPUT:
      return {
        ...state,
        resto: {
          ...state.resto,
          [action.attribute]: action.value
        }
      };
    case types.CHANGE_SEPULTADO_INPUT:
      return {
        ...state,
        resto: {
          ...state.resto,
          sepultado: {
            ...state.resto.sepultado,
            [action.attribute]: action.value
          }
        }
      };
    case types.CHANGE_CREMADO_INPUT:
      return {
        ...state,
        resto: {
          ...state.resto,
          cremado: {
            ...state.resto.cremado,
            [action.attribute]: action.value
          }
        }
      };
    case types.SAVE_RESTOS_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_RESTOS_DONE:
      return {
        ...state,
        resto: {
          ...state.resto,
          ...action.resto,
          sepultado: {
            ...state.resto.sepultado,
            ...action.resto.sepultado
          },
          cremado: {
            ...state.resto.cremado,
            ...action.resto.cremado
          }
        },
        isSaving: false
      };
    case types.SAVE_RESTOS_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
