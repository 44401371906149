import * as types from '../../actions/types/datos-personales-custodios-red-familiar/red-familiar';

const defaultFamiliar = {
  id: null,
  nombre_completo: '',
  correo_electronico: ''
};

const initialState = {
  familiar: {...defaultFamiliar},
  familiares: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let familiares = [];

  switch (action.type) {
    case types.GET_FAMILIARES_INIT:
      return {
        ...state,
        familiar: {...defaultFamiliar},
        familiares: [],
        isGetting: true,
        errors: null
      };
    case types.GET_FAMILIARES_DONE:
      return {
        ...state,
        familiares: [...action.familiares],
        isGetting: false
      };
    case types.GET_FAMILIARES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_FAMILIAR_INPUT:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          [action.attribute]: action.value
        }
      };
    case types.RESET_FAMILIAR_INPUTS:
      return {
        ...state,
        familiar: {...defaultFamiliar},
        errors: null
      };
    case types.SAVE_FAMILIAR_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_FAMILIAR_DONE:
      return {
        ...state,
        familiar: {...defaultFamiliar},
        familiares: [
          ...state.familiares,
          action.familiar
        ],
        isSaving: false
      };
    case types.SAVE_FAMILIAR_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.EDIT_FAMILIAR:
      return {
        ...state,
        familiar: {...action.familiar}
      };
    case types.UPDATE_FAMILIAR_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_FAMILIAR_DONE:
      familiares = state.familiares.map((familiar) => {
        if (familiar.id === action.familiar.id) {
          return action.familiar;
        }

        return familiar;
      });

      return {
        ...state,
        familiar: {...defaultFamiliar},
        familiares,
        isUpdating: false
      };
    case types.UPDATE_FAMILIAR_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_FAMILIAR_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      }
    case types.DELETE_FAMILIAR_DONE:
      familiares = state.familiares.filter((familiar) => familiar.id !== action.id);

      return {
        ...state,
        familiares,
        isDeleting: false
      };
    case types.DELETE_FAMILIAR_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
