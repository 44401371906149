import React from 'react';
import Plan from '../../../UI/Landing/Plan/Plan';
import BreakLine from '../../../UI/BreakLine/BreakLine';

const PlanBimestral = (props) => {
  const hasChildren = React.Children.count(props.children) > 0;

  return (
    <Plan>
      <Plan.Type>Servicio Pro<BreakLine /> Regular</Plan.Type>
      <Plan.Price>$ 599 <Plan.Recurrence>Bimestral</Plan.Recurrence></Plan.Price>
      <Plan.Description>
        <Plan.Paragraph>Guía y biografía</Plan.Paragraph>
        <Plan.Paragraph>Última voluntad</Plan.Paragraph>
        <Plan.Paragraph>Inventario de posesiones</Plan.Paragraph>
        <Plan.Paragraph>Legado personal</Plan.Paragraph>
        <Plan.Paragraph>Asesores y directorio</Plan.Paragraph>
        <Plan.Paragraph>Mensajes póstumos</Plan.Paragraph>
        <Plan.Paragraph>Mascostas</Plan.Paragraph>
        <Plan.Paragraph>Guía de elaboración de testamento</Plan.Paragraph>
      </Plan.Description>
      {hasChildren ? props.children : (<Plan.Link variant="secondary" to="/registrarse">Empezar</Plan.Link>)}
    </Plan>
  );
};

export default PlanBimestral;
