import * as types from '../../actions/types/empleo-pension/plan-pension';

const defaultPlanPension = {
  id: null,
  nombre: '',
  compania: '',
  numero_contrato: '',
  telefono: '',
  indicaciones: '',
  beneficiarios: [],
  asesor: {
    nombre_completo: '',
    telefono_oficina: '',
    telefono_particular: '',
    correo_electronico: ''
  }
};

const defaultBeneficiario = {
  nombre_completo: '',
  porcentaje: ''
};

const initialState = {
  planPension: { ...defaultPlanPension },
  planesPensiones: [],
  beneficiario: { ...defaultBeneficiario },
  beneficiarios: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let planesPensiones = [];

  switch (action.type) {
    case types.GET_PLANES_PENSIONES_INIT:
      return {
        ...state,
        planPension: {...defaultPlanPension},
        beneficiario: {...defaultBeneficiario},
        planesPensiones: [],
        isGetting: true,
        errors: null
      };
    case types.GET_PLANES_PENSIONES_DONE:
      return {
        ...state,
        planesPensiones: [...action.planesPensiones],
        isGetting: false
      };
    case types.GET_PLANES_PENSIONES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_PLAN_PENSION_INPUT:
      return {
        ...state,
        planPension: {
          ...state.planPension,
          [action.attribute]: action.value
        }
      };
    case types.CHANGE_BENEFICIARIO_INPUT:
      return {
        ...state,
        beneficiario: {
          ...state.beneficiario,
          [action.attribute]: action.value
        }
      };
    case types.ADD_BENEFICIARIO:
      return {
        ...state,
        planPension: {
          ...state.planPension,
          beneficiarios: [
            ...state.planPension.beneficiarios,
            action.beneficiario
          ]
        }
      };
    case types.RESET_BENEFICIARIO:
      return {
        ...state,
        beneficiario: {...defaultBeneficiario}
      };
    case types.REMOVE_BENEFICIARIO:
      const beneficiarios = [...state.planPension.beneficiarios];
      beneficiarios.splice(action.index, 1);

      return {
        ...state,
        planPension: {
          ...state.planPension,
          beneficiarios
        }
      };
    case types.CHANGE_ASESOR_INPUT:
      return {
        ...state,
        planPension: {
          ...state.planPension,
          asesor: {
            ...state.planPension.asesor,
            [action.attribute]: action.value
          }
        }
      };
    case types.RESET_PLAN_PENSION_INPUTS:
      return {
        ...state,
        planPension: {...defaultPlanPension},
        beneficiario: {...defaultBeneficiario},
        errors: null
      };
    case types.SAVE_PLAN_PENSION_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_PLAN_PENSION_DONE:
      return {
        ...state,
        planPension: {...defaultPlanPension},
        beneficiario: {...defaultBeneficiario},
        planesPensiones: [
          ...state.planesPensiones,
          action.planPension
        ],
        isSaving: false
      };
    case types.SAVE_PLAN_PENSION_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.EDIT_PLAN_PENSION:
      return {
        ...state,
        planPension: {...action.planPension}
      };
    case types.UPDATE_PLAN_PENSION_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_PLAN_PENSION_DONE:
      planesPensiones = state.planesPensiones.map((planPension) => {
        if (planPension.id === action.planPension.id) {
          return action.planPension;
        }

        return planPension;
      });

      return {
        ...state,
        planPension: {...defaultPlanPension},
        beneficiario: {...defaultBeneficiario},
        planesPensiones,
        isUpdating: false
      };
    case types.UPDATE_PLAN_PENSION_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_PLAN_PENSION_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      }
    case types.DELETE_PLAN_PENSION_DONE:
      planesPensiones = state.planesPensiones.filter((planPension) => planPension.id !== action.id);

      return {
        ...state,
        planesPensiones,
        isDeleting: false
      };
    case types.DELETE_PLAN_PENSION_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
