import React from 'react';

const IconPhone = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="m298.67 25.6h-85.333c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h85.333c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533z" />
	  <path d="m358.4 25.6h-8.533c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h8.533c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533z" />
	  <path d="m266.6 435.2h-21.188c-12.979 0-23.543 10.564-23.543 23.543v4.122c0 12.979 10.564 23.535 23.535 23.535h21.188c12.979 0 23.543-10.556 23.543-23.535v-4.122c0-12.979-10.564-23.543-23.535-23.543zm6.469 27.665c0 3.567-2.901 6.468-6.468 6.468h-21.189c-3.575 0-6.477-2.901-6.477-6.468v-4.122c0-3.575 2.901-6.477 6.477-6.477h21.18c3.576 0 6.477 2.901 6.477 6.477v4.122z" />
	  <path d="m370.23 0h-228.45c-17.007 0-30.848 13.841-30.848 30.848v450.3c0 17.007 13.841 30.848 30.848 30.848h228.44c17.007 0 30.848-13.841 30.848-30.839v-450.31c1e-3 -17.007-13.84-30.848-30.839-30.848zm13.773 481.15c0 7.595-6.178 13.781-13.773 13.781h-228.45c-7.603 0-13.781-6.187-13.781-13.773v-450.31c0-7.595 6.178-13.781 13.781-13.781h228.44c7.603 0 13.781 6.187 13.781 13.781v450.3z" />
	  <path d="m392.53 51.2h-273.07c-4.71 0-8.533 3.823-8.533 8.533v358.4c0 4.71 3.823 8.533 8.533 8.533h273.07c4.71 0 8.533-3.823 8.533-8.533v-358.4c0-4.71-3.823-8.533-8.534-8.533zm-8.533 358.4h-256v-341.33h256v341.33z" />
  </svg>
);

export default IconPhone;
