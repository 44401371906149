import React from 'react';
import Plan from '../../../UI/Landing/Plan/Plan';
import BreakLine from '../../../UI/BreakLine/BreakLine';

const PlanFree = (props) => {
  const hasChildren = React.Children.count(props.children) > 0;

  return (
    <Plan>
      <Plan.Type>Regular<BreakLine /> &nbsp;</Plan.Type>
      <Plan.Price>Gratis <Plan.Recurrence>&nbsp;</Plan.Recurrence></Plan.Price>
      <Plan.Description>
        <Plan.Paragraph>Registro</Plan.Paragraph>
        <Plan.Paragraph>Datos generales</Plan.Paragraph>
        <Plan.Paragraph>Selección de custodios</Plan.Paragraph>
        <Plan.Paragraph>Contactos relevantes</Plan.Paragraph>
        <Plan.Paragraph>Instrucción o mensaje póstumo</Plan.Paragraph>
      </Plan.Description>
      {hasChildren ? props.children : (<Plan.Link variant="secondary" to="/registrarse">Empezar</Plan.Link>)}
    </Plan>
  );
};

export default PlanFree;
