export const GET_SECUESTRO_DESAPARICION_INIT = 'GET_SECUESTRO_DESAPARICION_INIT';
export const GET_SECUESTRO_DESAPARICION_DONE = 'GET_SECUESTRO_DESAPARICION_DONE';
export const GET_SECUESTRO_DESAPARICION_FAIL = 'GET_SECUESTRO_DESAPARICION_FAIL';

export const CHANGE_SECUESTRO_DESAPARICION_INPUT = 'CHANGE_SECUESTRO_DESAPARICION_INPUT';

export const SAVE_SECUESTRO_DESAPARICION_INIT = 'SAVE_SECUESTRO_DESAPARICION_INIT';
export const SAVE_SECUESTRO_DESAPARICION_DONE = 'SAVE_SECUESTRO_DESAPARICION_DONE';
export const SAVE_SECUESTRO_DESAPARICION_FAIL = 'SAVE_SECUESTRO_DESAPARICION_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
