import * as types from '../../actions/types/patrimonio/vehiculos';

const defaultVehiculo = {
  id: null,
  marca_tipo: '',
  modelo: '',
  serie: '',
  lugar_registro: '',
  documento_propiedad_fisico: '',
  otros_documentos_fisico: '',
  indicaciones: ''
};

const initialState = {
  vehiculo: {...defaultVehiculo},
  vehiculos: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let vehiculos = [];

  switch (action.type) {
    case types.GET_VEHICULOS_INIT:
      return {
        ...state,
        vehiculo: {...defaultVehiculo},
        vehiculos: [],
        isGetting: true,
        errors: null
      };
    case types.GET_VEHICULOS_DONE:
      return {
        ...state,
        vehiculos: [...action.vehiculos],
        isGetting: false
      };
    case types.GET_VEHICULOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_VEHICULO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_VEHICULO_INPUTS:
      return {
        ...state,
        vehiculo: {...defaultVehiculo},
        errors: null
      };
    case types.SAVE_VEHICULO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_VEHICULO_DONE:
      return {
        ...state,
        vehiculo: {...defaultVehiculo},
        vehiculos: [
          ...state.vehiculos,
          action.vehiculo
        ],
        isSaving: false
      };
    case types.SAVE_VEHICULO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_VEHICULO_INPUTS:
      return {
        ...state,
        vehiculo: {...action.vehiculo}
      };
    case types.UPDATE_VEHICULO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_VEHICULO_DONE:
      vehiculos = state.vehiculos.map((vehiculo) => {
        if (vehiculo.id === action.vehiculo.id) {
          return action.vehiculo;
        }

        return vehiculo;
      });

      return {
        ...state,
        vehiculo: {...defaultVehiculo},
        vehiculos,
        isUpdating: false
      };
    case types.UPDATE_VEHICULO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_VEHICULO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_VEHICULO_DONE:
      vehiculos = state.vehiculos.filter((vehiculo) => vehiculo.id !== action.id);

      return {
        ...state,
        vehiculos,
        isDeleting: false
      };
    case types.DELETE_VEHICULO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
