import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isBrowser } from 'react-device-detect';
import Button from '../../Button/Button';
import styles from './Plan.module.scss';

const Plan = (props) => {
  const planStyles = classNames(styles.Plan, {
    [styles.isActive]: props.active,
    [styles.Scale]: isBrowser
  });

  return (
    <div className={planStyles}>
      {props.children}
    </div>
  );
};

Plan.propTypes = {
  active: PropTypes.bool
};

const Type = (props) => (
  <h3 className={styles.Type}>
    {props.children}
  </h3>
);

const Price = (props) => (
  <h4 className={styles.Price}>{props.children}</h4>
);

const Recurrence = (props) => (
  <span className={styles.Recurrence}>{props.children}</span>
);

const Description = (props) => (
  <div className={styles.Description}>
    {props.children}
  </div>
);

const Paragraph = (props) => (
  <p className={styles.Paragraph}>{props.children}</p>
);

const Link = (props) => (
  <Button link variant={props.variant} to={props.to}>
    {props.children}
  </Button>
);

Link.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'light']).isRequired,
  to: PropTypes.string.isRequired
};

Plan.Type = Type;
Plan.Price = Price;
Plan.Recurrence = Recurrence;
Plan.Description = Description;
Plan.Paragraph = Paragraph;
Plan.Link = Link;

export default Plan;
