import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import moment from 'moment';
import 'moment/locale/es';
import rootReducer from './reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { MONTH_SHORT } from './constants';
import * as serviceWorker from './serviceWorker';

moment.updateLocale('es', {
  monthsShort : function (momentToFormat, format) {
    if (/MMM/.test(format)) {
        return MONTH_SHORT[momentToFormat.month()];
    }

    return momentToFormat.month();
  }
});

const createStoreWithMiddleware = compose(applyMiddleware(thunk)(createStore));
const store = createStoreWithMiddleware(rootReducer);

const app = (
  <Provider store={store}>
      <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
