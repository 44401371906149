import * as types from '../actions/types/instrucciones-particulares';

const defaultInstruccionesParticulares = {
  texto: '',
  audio: '',
  video: '',
  is_grabar_audio: false,
  is_grabar_video: false
};

const initialState = {
  instruccionesParticulares: {...defaultInstruccionesParticulares},
  isGetting: false,
  isSaving: false,
  isDownloadingAudio: false,
  isDownloadingVideo: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INSTRUCCIONES_PARTICULARES_INIT:
      return {
        ...state,
        instruccionesParticulares: {...defaultInstruccionesParticulares},
        isGetting: true,
        errors: null
      };
    case types.GET_INSTRUCCIONES_PARTICULARES_DONE:
      return {
        ...state,
        instruccionesParticulares: {
          ...state.instruccionesParticulares,
          ...action.instruccionesParticulares
        },
        isGetting: false
      };
    case types.GET_INSTRUCCIONES_PARTICULARES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_INSTRUCCIONES_PARTICULARES_INPUT:
      return {
        ...state,
        instruccionesParticulares: {
          ...state.instruccionesParticulares,
          [action.attribute]: action.value
        }
      };
    case types.SAVE_INSTRUCCIONES_PARTICULARES_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_INSTRUCCIONES_PARTICULARES_DONE:
      return {
        ...state,
        instruccionesParticulares: {
          ...state.instruccionesParticulares,
          ...action.instruccionesParticulares
        },
        isSaving: false
      };
    case types.SAVE_INSTRUCCIONES_PARTICULARES_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.DOWNLOAD_AUDIO_INIT:
      return {
        ...state,
        isDownloadingAudio: true
      };
    case types.DOWNLOAD_AUDIO_DONE:
      return {
        ...state,
        isDownloadingAudio: false
      };
    case types.DOWNLOAD_AUDIO_FAIL:
      return {
        ...state,
        isDownloadingAudio: false,
        errors: action.errors
      };
    case types.DOWNLOAD_VIDEO_INIT:
      return {
        ...state,
        isDownloadingVideo: true
      };
    case types.DOWNLOAD_VIDEO_DONE:
      return {
        ...state,
        isDownloadingVideo: false
      };
    case types.DOWNLOAD_VIDEO_FAIL:
      return {
        ...state,
        isDownloadingVideo: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
