import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Layout from '../../components/Auth/Layout/Layout';
import ColorPrimary from '../../components/UI/Colors/ColorPrimary/ColorPrimary';
import TogglePassword from '../../components/Auth/TogglePassword/TogglePassword';
import Button from '../../components/UI/Button/Button';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

const RegisterPage = () => {
  const history = useHistory();
  const [togglePassword, setTogglePassword] = React.useState(false);
  const registeredUser = useSelector((state) => state.auth.registeredUser);
  const isValidRegisteredUser = useSelector((state) => state.auth.isValidRegisteredUser);
  const errors = useSelector((state) => state.auth.errors);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { ...registeredUser },
    validationSchema: Yup.object({
      nombre_completo: Yup.string()
        .required('El campo "Nombre completo" es obligatorio.'),
      celular: Yup.string()
        .matches(/^\d+$/, 'Debe de ingresar un número de celular valido.')
        .length(10, 'El campo celular debe ser un número de 10 dígitos.')
        .required('El campo "Celular" es obligatorio.'),
      email: Yup.string()
        .email('El campo "Correo electrónico" debe ser una dirección de correo válida.')
        .required('El campo "Correo electrónico" es obligatorio.'),
      password: Yup.string()
        .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+){8,}$/, 'El formato del campo "Contraseña" es inválido.')
        .required('El campo "Contraseña" es obligatorio.'),
      politicas_privacidad: Yup.bool().oneOf([true]),
      terminos_condiciones: Yup.bool().oneOf([true])
    }),
    onSubmit: (values) => dispatch(actions.storeRegisteredUser(values))
  });

  const onTogglePassword = (value) => {
    setTogglePassword(value);
  };

  React.useEffect(() => {
    if (isValidRegisteredUser) {
      setTogglePassword(false);
      formik.setSubmitting(false);
      history.push('/planes');
    }

    // eslint-disable-next-line
  }, [isValidRegisteredUser]);

  React.useEffect(() => {
    if (helpers.isObject(errors)) {
      formik.setErrors(errors);
      formik.setSubmitting(false);
    }

    // eslint-disable-next-line
  }, [errors]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    
    dispatch(actions.resetRegisteredUser());

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {(styles) => (
        <>
          <h2 className={styles.Title}>Hoy, mañana y siempre</h2>
          <p className={styles.Paragraph}>Un legado de amor y seguridad para sus seres queridos por si algún día llegas a faltar.</p>
          <p className={styles.Paragraph}>Deja a resguardo en línea todo lo que a tus seres queridos desees transmitir como bienes, pensamientos, documentos y recuerdos importantes para ti.</p>
          <Form className={styles.Form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Form.Group className={styles.Group} controlId="nombre_completo">
              <Form.Label className={styles.Label}>Nombre completo</Form.Label>
              <Form.Control
                type="text"
                name="nombre_completo"
                className={styles.Control}
                autoFocus
                maxLength={100}
                isInvalid={formik.errors.nombre_completo && formik.touched.nombre_completo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nombre_completo}
              />
              {formik.errors.nombre_completo && formik.touched.nombre_completo && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.nombre_completo}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className={styles.Group} controlId="celular">
              <Form.Label className={styles.Label}>Número de celular</Form.Label>
              <Form.Control
                type="text"
                name="celular"
                className={styles.Control}
                isInvalid={formik.errors.celular && formik.touched.celular}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.celular}
              />
              {formik.errors.celular && formik.touched.celular && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.celular}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className={styles.Group} controlId="email">
              <Form.Label className={styles.Label}>Correo electrónico</Form.Label>
              <Form.Text className={classNames(styles.Text, styles.Top)}>Tu correo electrónico será tu nombre de usuario</Form.Text>
              <Form.Control
                type="email"
                name="email"
                className={styles.Control}
                isInvalid={formik.errors.email && formik.touched.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className={styles.Group} controlId="password">
              <Form.Label className={classNames(styles.Label, styles.Password)}>
                Contraseña <TogglePassword onToggle={onTogglePassword} />
              </Form.Label>
              <Form.Control
                type={togglePassword ? 'text' : 'password'}
                name="password"
                className={styles.Control}
                isInvalid={formik.errors.password && formik.touched.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <Form.Text className={classNames(styles.Text, styles.Bottom)}><ColorPrimary>*</ColorPrimary>8 Caracteres mínimo <ColorPrimary>*</ColorPrimary>Un número <ColorPrimary>*</ColorPrimary>Una letra</Form.Text>
              {formik.errors.password && formik.touched.password && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Check
              custom
              type="checkbox"
              name="politicas_privacidad"
              id="politicas_privacidad"
              className={styles.Check}
              checked={formik.values.politicas_privacidad}
              label={<>Acepto la <ColorPrimary>política de privacidad</ColorPrimary></>}
              isInvalid={formik.errors.politicas_privacidad && formik.touched.politicas_privacidad}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.politicas_privacidad}
            />
            <Form.Check
              custom
              type="checkbox"
              name="terminos_condiciones"
              id="terminos_condiciones"
              className={styles.Check}
              checked={formik.values.terminos_condiciones}
              label={<>Acepto los <ColorPrimary>términos y condiciones</ColorPrimary></>}
              isInvalid={formik.errors.terminos_condiciones && formik.touched.terminos_condiciones}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.terminos_condiciones}
            />
            <Button
              type="submit"
              variant="primary"
              className={styles.Button}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Validando...' : 'Crear cuenta gratis'}
            </Button>
          </Form>
        </>
      )}
    </Layout>
  );
};

export default RegisterPage;
