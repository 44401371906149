export const LOGIN_START = 'LOGIN_START';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const STORE_REGISTERED_USER_START = 'STORE_REGISTERED_USER_START';
export const STORE_REGISTERED_USER_DONE = 'STORE_REGISTERED_USER_DONE';
export const STORE_REGISTERED_USER_FAIL = 'STORE_REGISTERED_USER_FAIL';

export const RESET_REGISTERED_USER = 'RESET_REGISTERED_USER';

export const REGISTER_FREE_PLAN_START = 'REGISTER_FREE_PLAN_START';
export const REGISTER_FREE_PLAN_DONE = 'REGISTER_FREE_PLAN_DONE';
export const REGISTER_FREE_PLAN_FAIL = 'REGISTER_FREE_PLAN_FAIL';

export const PAYPAL_CREATE_ORDER_START = 'PAYPAL_CREATE_ORDER_START';
export const PAYPAL_CREATE_ORDER_FAIL = 'PAYPAL_CREATE_ORDER_FAIL';

export const PAYPAL_APPROVE_ORDER_START = 'PAYPAL_APPROVE_ORDER_START';
export const PAYPAL_APPROVE_ORDER_DONE = 'PAYPAL_APPROVE_ORDER_DONE';
export const PAYPAL_APPROVE_ORDER_FAIL = 'PAYPAL_APPROVE_ORDER_FAIL';

export const CREDIT_CARD_PAY_ORDER_START = 'CREDIT_CARD_PAY_ORDER_START';
export const CREDIT_CARD_PAY_ORDER_DONE = 'CREDIT_CARD_PAY_ORDER_DONE';
export const CREDIT_CARD_PAY_ORDER_FAIL = 'CREDIT_CARD_PAY_ORDER_FAIL';

export const OXXO_PAY_ORDER_START = 'OXXO_PAY_ORDER_START';
export const OXXO_PAY_ORDER_DONE = 'OXXO_PAY_ORDER_DONE';
export const OXXO_PAY_ORDER_FAIL = 'OXXO_PAY_ORDER_FAIL';

export const LOGOUT = 'LOGOUT';
