import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as helpers from '../../../helpers';
import styles from './StepProgressBar.module.scss';

const Context = React.createContext({});

const StepProgressBar = (props) => {
  const [activeKeys, setActiveKeys] = React.useState([]);
  const classes = classNames(styles.StepProgressBar, props.className);

  const onSelectKey = (activeKey) => {
    setActiveKeys(helpers.getDecrementNumbers(+activeKey));
  };

  React.useEffect(() => {
    onSelectKey(props.activeKey);
  }, [props.activeKey]);

  return (
    <Context.Provider value={{ activeKeys }}>
      <div className={classes}>
        {props.children}
      </div>
    </Context.Provider>
  );
};

StepProgressBar.defaultProps = {
  activeKey: '0'
};

StepProgressBar.propTypes = {
  className: PropTypes.string,
  activeKey: PropTypes.string
};

const Step = (props) => {
  const { activeKeys } = React.useContext(Context);
  
  const classes = classNames(styles.Step, {
    [styles.isActive]: (activeKeys.includes(+props.eventKey))
  });

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

Step.propTypes = {
  eventKey: PropTypes.string.isRequired
};

StepProgressBar.Step = Step;

export default StepProgressBar;
