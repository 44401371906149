export const getWindowSize = () => {
  const w = window
      , d = document
      , e = d.documentElement
      , g = d.getElementsByName('body')[0]
      , x = w.innerWidth || e.clientWidth || g.clientWidth
      , y = w.innerHeight || e.clientHeight || g.clientHeight
      ;

  return {
    width: x,
    height: y
  };
};
