import React from 'react';

const IconEyeShow = (props) => (
<svg {...props} viewBox="0 0 489.66 489.66" xmlns="http://www.w3.org/2000/svg">
	<path d="m194.83 244.83c0 27.57 22.43 50 50 50s50-22.43 50-50-22.43-50-50-50-50 22.43-50 50z" />
	<path d="m485.31 237.32c-23.974-41.44-58.446-76.197-99.691-100.51-42.473-25.038-91.117-38.28-140.68-38.3-0.037 0-0.074-1e-3 -0.112-1e-3s-0.074 1e-3 -0.112 1e-3c-49.564 0.02-98.208 13.262-140.68 38.3-41.245 24.314-75.718 59.07-99.691 100.51l-4.345 7.511 4.345 7.511c23.974 41.44 58.446 76.197 99.691 100.51 42.473 25.038 91.117 38.28 140.68 38.3 0.037 0 0.074 1e-3 0.112 1e-3s0.074-1e-3 0.112-1e-3c49.564-0.02 98.208-13.262 140.68-38.3 41.245-24.314 75.718-59.07 99.691-100.51l4.345-7.511-4.345-7.511zm-124.16 7.511c0 64.105-52.125 116.26-116.22 116.32-0.036 0-0.071 1e-3 -0.107 1e-3s-0.071-1e-3 -0.107-1e-3c-64.092-0.058-116.22-52.217-116.22-116.32s52.125-116.26 116.22-116.32c0.036 0 0.071-1e-3 0.107-1e-3s0.071 1e-3 0.107 1e-3c64.092 0.058 116.22 52.217 116.22 116.32zm-326.31 0c22.94-36.587 54.809-66.03 91.791-86.144-17.673 24.184-28.124 53.964-28.124 86.144s10.45 61.96 28.124 86.144c-36.982-20.114-68.851-49.557-91.791-86.144zm328.19 86.144c17.673-24.184 28.124-53.964 28.124-86.144s-10.45-61.96-28.124-86.144c36.981 20.114 68.85 49.557 91.791 86.144-22.941 36.587-54.81 66.03-91.791 86.144z" />
</svg>
);

export default IconEyeShow;
