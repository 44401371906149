export const GET_ACCIONES_NEGOCIOS_INIT = 'GET_ACCIONES_NEGOCIOS_INIT';
export const GET_ACCIONES_NEGOCIOS_DONE = 'GET_ACCIONES_NEGOCIOS_DONE';
export const GET_ACCIONES_NEGOCIOS_FAIL = 'GET_ACCIONES_NEGOCIOS_FAIL';

export const CHANGE_ACCION_NEGOCIO_INPUTS = 'CHANGE_ACCION_NEGOCIO_INPUTS';

export const ADD_SOCIO = 'ADD_SOCIO';
export const RESET_SOCIO = 'RESET_SOCIO';
export const REMOVE_SOCIO = 'REMOVE_SOCIO';

export const RESET_ACCION_NEGOCIO_INPUTS = 'RESET_ACCION_NEGOCIO_INPUTS';

export const SAVE_ACCION_NEGOCIO_INIT = 'SAVE_ACCION_NEGOCIO_INIT';
export const SAVE_ACCION_NEGOCIO_DONE = 'SAVE_ACCION_NEGOCIO_DONE';
export const SAVE_ACCION_NEGOCIO_FAIL = 'SAVE_ACCION_NEGOCIO_FAIL';

export const LOAD_ACCION_NEGOCIO_INPUTS = 'LOAD_ACCION_NEGOCIO_INPUTS';

export const UPDATE_ACCION_NEGOCIO_INIT = 'UPDATE_ACCION_NEGOCIO_INIT';
export const UPDATE_ACCION_NEGOCIO_DONE = 'UPDATE_ACCION_NEGOCIO_DONE';
export const UPDATE_ACCION_NEGOCIO_FAIL = 'UPDATE_ACCION_NEGOCIO_FAIL';

export const DELETE_ACCION_NEGOCIO_INIT = 'DELETE_ACCION_NEGOCIO_INIT';
export const DELETE_ACCION_NEGOCIO_DONE = 'DELETE_ACCION_NEGOCIO_DONE';
export const DELETE_ACCION_NEGOCIO_FAIL = 'DELETE_ACCION_NEGOCIO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
