import React from 'react';
import { Element as Section } from 'react-scroll';
import useElementSize from '../../hooks/useElementSize';
import Navigation from '../../components/Landing/Navigation/Navigation';
import Galeria from '../../components/Landing/Galeria/Galeria';
import ComoFunciona from '../../components/Landing/ComoFunciona/ComoFunciona';
import Ventajas from '../../components/Landing/Ventajas/Ventajas';
import Planes from '../../components/Landing/Planes/Planes';
import Ayuda from '../../components/Landing/Ayuda/Ayuda';
import Copyright from '../../components/Landing/Copyright/Copyright';

const Landing = () => {
  const [navbarRef, navbarSize] = useElementSize();

  return (
    <React.Fragment>
      <Navigation navbarRef={navbarRef} />
      <Section name="inicio">
        <Galeria navbarHeight={navbarSize.height} />
      </Section>
      <Section name="como-funciona">
        <ComoFunciona />
      </Section>
      <Ventajas />
      <Section name="planes">
        <Planes />
      </Section>
      <Section name="ayuda">
        <Ayuda />
      </Section>
      <Copyright />
    </React.Fragment>
  );
};

export default Landing;
