import React from 'react';
import classNames from 'classnames';
import styles from './Background.module.scss';

const Background = (props) => {
  const backgroundClasses = classNames(styles.Background, {
    [styles.Color]: props.color
  });

  return (
    <div className={backgroundClasses}>
      {props.children}
    </div>
  );
};

export default Background;
