import { httpStatus } from '../constants';

export const resolveErrors = ({ data, status }, toList = false) => {
  const errors = data.errors;

  if (status === httpStatus.UNPROCESSABLE_ENTITY) {
    if (toList) {
      return Object.keys(errors)
        .reduce((obj, key) => {
          obj.push(errors[key][0]);

          return obj;
        }, []);
    }

    return Object.keys(errors)
      .reduce((obj, key) => {
        obj[key] = errors[key][0];

        return obj;
      }, {});
  }

  if (status === httpStatus.INTERNAL_SERVER_ERROR) {
    return errors;
  }
};
