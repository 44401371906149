import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../Title/Title';
import Background from '../../UI/Landing/Backgroud/Background';
import image from '../../../assets/images/ventajas.png';
import styles from './Ventajas.module.scss';

const Ventajas = () => (
  <Background color>
    <Container>
      <Row className={styles.Ventajas}>
        <Col>
          <Row>
            <Col>
              <Title center quote="Por si no te vuelvo a ver" text="Ventajas" />
            </Col>
          </Row>
          <Row>
            <Col className={styles.Graphic}>
              <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                  <img src={image} className={styles.Image} alt="Ventajas" />
                </Col>
              </Row>
              <Row className={styles.List}>
                <Col>
                  <Row className={styles.Item}>
                    <Col lg={4}>
                      <div className={`${styles.Ventaja} ${styles.Right}`}>
                        <h5 className={`${styles.Title}`}>1. Orden</h5>
                        <p className={styles.Text}>Se proporciona una guía con formularios para llevar a cabo un inventario eficaz de tus bienes y deseos.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.Item}>
                    <Col lg={{ span: 6, offset: 6 }}>
                      <div className={`${styles.Ventaja} ${styles.Left}`}>
                        <h5 className={`${styles.Title}`}>2. Resguardo</h5>
                        <p className={styles.Text}>Almacenamiento y resguardo de la información de acuerdo a tus instrucciones y solo accessible a los custodios que tu elijas.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.Item}>
                    <Col lg={{ span: 4, offset: 8 }}>
                      <div className={`${styles.Ventaja} ${styles.Left}`}>
                        <h5 className={`${styles.Title}`}>3. Tranquilidad</h5>
                        <p className={styles.Text}>Entrega de la información digital a los custodios basado en una prueba de vida, primero directamente a tí, y en caso de no obtener respuesta con tus custodios.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.Item}>
                    <Col lg={6}>
                      <div className={`${styles.Ventaja} ${styles.Right} ${styles.Last}`}>
                        <h5 className={`${styles.Title}`}>4. Legado y trascendencia</h5>
                        <p className={styles.Text}>Permite que generaciones futuras se beneficien con tus consejos y vivencias.</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Background>
);

export default Ventajas;
