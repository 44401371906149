import React from 'react';

const IconChecklist = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="m400 152h-144c-5.523 0-10 4.477-10 10s4.477 10 10 10h144c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m365.01 202.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07z" />
    <path d="m263.06 45.931c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07z" />
    <path d="m315.88 200h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m400 260h-144c-5.523 0-10 4.477-10 10s4.477 10 10 10h144c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m365.01 310.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07z" />
    <path d="m315.88 308h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m400 368h-144c-5.523 0-10 4.477-10 10s4.477 10 10 10h144c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m365.01 418.93c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07z" />
    <path d="m315.88 416h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10z" />
    <path d="m419.24 39.001h-76.379c-11.041-10.521-25.966-17.001-42.385-17.001h-8.76c-6.697-13.258-20.456-22-35.719-22s-29.021 8.742-35.719 22h-8.781c-16.419 0-31.343 6.48-42.384 17.001h-76.357c-26.885 0-48.758 21.873-48.758 48.758v375.48c0 26.885 21.873 48.758 48.758 48.758h326.48c26.885 0 48.758-21.873 48.758-48.758v-375.48c1e-3 -26.885-21.872-48.758-48.757-48.758zm-207.74 2.999h15.586c4.498 0 8.442-3.003 9.639-7.338 2.385-8.633 10.311-14.662 19.275-14.662s16.89 6.029 19.274 14.662c1.197 4.335 5.142 7.338 9.639 7.338h15.565c21.705 0 39.571 16.75 41.354 38.001h-171.69c1.783-21.251 19.65-38.001 41.354-38.001zm236.5 421.24c0 15.857-12.901 28.758-28.758 28.758h-326.48c-15.857 0-28.758-12.901-28.758-28.758v-375.48c0-15.857 12.901-28.758 28.758-28.758h62.347c-3.276 7.512-5.105 15.794-5.105 24.5v6.5c0 5.523 4.477 10 10 10h191.98c5.523 0 10-4.477 10-10v-6.5c0-8.705-1.829-16.988-5.105-24.5h62.368c15.857 0 28.758 12.901 28.758 28.758v375.48z" />
    <path d="m192.41 149.6c-3.905-3.905-10.237-3.905-14.142-1e-3l-42.762 42.763-13.173-13.174c-3.905-3.904-10.237-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143l20.245 20.245c1.953 1.953 4.512 2.929 7.071 2.929s5.119-0.976 7.071-2.929l49.833-49.833c3.905-3.905 3.905-10.237 0-14.143z" />
    <path d="m168 368h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28v28z" />
    <path d="m168 260h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28v28z" />
  </svg>
);

export default IconChecklist;
