export const GET_DATOS_PERSONALES_INIT = 'GET_DATOS_PERSONALES_INIT';
export const GET_DATOS_PERSONALES_DONE = 'GET_DATOS_PERSONALES_DONE';
export const GET_DATOS_PERSONALES_FAIL = 'GET_DATOS_PERSONALES_FAIL';

export const CHANGE_DATOS_PERSONALES_INPUT = 'CHANGE_DATOS_PERSONALES_INPUT';

export const SAVE_DATOS_PERSONALES_INIT = 'SAVE_DATOS_PERSONALES_INIT';
export const SAVE_DATOS_PERSONALES_DONE = 'SAVE_DATOS_PERSONALES_DONE';
export const SAVE_DATOS_PERSONALES_FAIL = 'SAVE_DATOS_PERSONALES_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
