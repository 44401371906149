import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Title from '../Title/Title';
import Background from '../../UI/Landing/Backgroud/Background';
import Button from '../../UI/Button/Button';
import styles from './Ayuda.module.scss';

const Ayuda = () => {
  return (
    <Background color>
      <Container>
        <Row>
          <Col>
            <Title quote="Por si no te vuelvo a ver" text="Ayuda" />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Accordion className={styles.Accordion} defaultActiveKey="0">
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="0">
                  <Card.Body>
                    La vida está llena de incertidumbre y situaciones fuera de tu control. <i>Por si no te vuelvo a ver</i> te brinda la oportunidad de notificar automáticamente a un beneficiario tu voluntad, instrucciones y deseos en caso de presentarse una enfermedad o ausencia. El sistema te permite, desde dejar un mensaje póstumo, hasta apoyarte con una serie de formularios para documentar y resguardar información que desees transmitir a tus seres queridos, tales como última voluntad, mensajes póstumos, seguros vigentes, bienes materiales, instrucciones con respecto a mascotas, etc. <br /><br /> El valor de la información que entregas y almacenas digitalmente es invaluable, ya que permites a tus seres queridos afrontar la difícil situación en la que podrían llegar a encontrarse en caso de tu ausencia, así como mantener un contacto vivo a través de medios digitales con tu persona, con quien eres, con tus deseos y voluntad.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="0">
                  ¿Por qué debería usar Por si no te vuelvo a ver?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="1">
                  <Card.Body>
                    <i>Por si no te vuelvo a ver es seguro</i>, ya que tu información personal y financiera se mantiene privada y no se comparte con nadie. Toda la información de nuestro sitio web está encriptada, por lo que los datos viajan protegidos por Internet con un Certificado SSL.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="1">
                  ¿Es seguro?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="2">
                  <Card.Body>
                    Para crear tu cuenta sólo debes hacer clic en el siguiente enlace <Link to="/registrarse">https://www.porsinotevuelvoaver.com/registrarse</Link>, deberás rellenar los datos del formulario y dar clic en el botón CREAR CUENTA GRATIS, posteriormente deberás seleccionar uno de nuestros planes.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="2">
                  ¿Cómo creo mi cuenta?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="3">
                  <Card.Body>
                    Puedes pagar con tu cuenta de PayPal, con tarjeta de crédito, con tarjeta de débito o pagar en tiendas OXXO mediante MercadoPago.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="3">
                  ¿Cuáles son los métodos de pago?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="4">
                  <Card.Body>
                    En nuestro <strong>Plan Gratis</strong> únicamente puedes capturar tus datos personales, los datos de un custodio, los datos de un asesor o contacto y escribir instrucciones particulares limitadas para tu custodio.<br /><br />Mientras que en nuestro <strong>Plan Pro</strong> puedes capturar los datos de tus custodios, todos tus asesores y contactos, pólizas de seguro, tu patrimonio, los datos de tu empleo y pensión, tus últimos deseos, los datos de tus mascotas, información de tus cuentas de correo y redes sociales, todo lo anterior de forma ilimitada. Además, podrás redactar o grabar un audio o video con tus últimos deseos, tu historia y legado, así como las instrucciones particulares para tus custodios.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="4">
                  ¿Cuáles son las limitaciones del Plan Gratis?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="5">
                  <Card.Body>
                    Al ingresar a tu cuenta, deberás hacer clic en el botón <i>ACTUALIZAR MI PLAN</i> y seleccionar el método de pago correspondiente. Una vez realizado el pago, tu cuenta gratuita se actualizará a una cuenta pro.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="5">
                  ¿Cómo puedo actualizar mi plan gratis a un plan pro?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="6">
                  <Card.Body>
                    Si requieres una atención personalizada deberás rellenar nuestro formulario de contacto.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="6">
                  ¿A dónde acudo si tengo dudas o necesito ayuda?
                </Accordion.Toggle>
              </Card>
              <Card className={styles.Card}>
                <Accordion.Collapse className={styles.Body} eventKey="7">
                  <Card.Body>
                    Te recordamos que tu usuario es el correo electrónico con el que te registraste.<br /><br />Si olvidaste tu contraseña, sólo debes hacer clic en este enlace <Link to="/recuperar-contrasenia">https://www.porsinotevuelvoaver.com/recuperar-contrasenia</Link>, deberás capturar tu usuario y dar clic en el botón <i>ENVIAR</i>, posteriormente te llegará un enlace a tu correo electrónico para realizar el cambio de tu contraseña.
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} className={styles.Header} eventKey="7">
                  ¿Qué pasa si olvido mi usuario o contraseña?
                </Accordion.Toggle>
              </Card>
            </Accordion>
          </Col>
          <Col lg={6}>
            <Form className={styles.Form}>
              <h2 className={styles.Title}>Formulario de contacto</h2>
              <p className={styles.Quote}>Si requieres una atención personalizada, ponte en contacto con nosotros a través del siguiente formulario.</p>
              <Form.Group controlId="nombre" className={styles.Group}>
                <Form.Label className={styles.Label}>Nombre</Form.Label>
                <Form.Control type="text" name="nombre" className={styles.Control} />
              </Form.Group>
              <Form.Group controlId="correo_electronico" className={styles.Group}>
                <Form.Label className={styles.Label}>Correo electrónico</Form.Label>
                <Form.Control type="email" name="correo_electronico" className={styles.Control} />
              </Form.Group>
              <Form.Group controlId="telefono" className={styles.Group}>
                <Form.Label className={styles.Label}>Teléfono</Form.Label>
                <Form.Control type="tel" name="telefono" className={styles.Control} />
              </Form.Group>
              <Form.Group controlId="mensaje" className={styles.Group}>
                <Form.Label className={styles.Label}>Mensaje</Form.Label>
                <Form.Control as="textarea" name="mensaje" className={styles.Control} rows="4" />
              </Form.Group>
              <Button variant="primary" align="right">Enviar</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Background>
  );
};

export default Ayuda;
