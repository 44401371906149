export const GET_MASCOTAS_INIT = 'GET_MASCOTAS_INIT';
export const GET_MASCOTAS_DONE = 'GET_MASCOTAS_DONE';
export const GET_MASCOTAS_FAIL = 'GET_MASCOTAS_FAIL';

export const CHANGE_MASCOTA_INPUT = 'CHANGE_MASCOTA_INPUT';

export const RESET_MASCOTA_INPUTS = 'RESET_MASCOTA_INPUTS';

export const SAVE_MASCOTA_INIT = 'SAVE_MASCOTA_INIT';
export const SAVE_MASCOTA_DONE = 'SAVE_MASCOTA_DONE';
export const SAVE_MASCOTA_FAIL = 'SAVE_MASCOTA_FAIL';

export const EDIT_MASCOTA = 'EDIT_MASCOTA';

export const UPDATE_MASCOTA_INIT = 'UPDATE_MASCOTA_INIT';
export const UPDATE_MASCOTA_DONE = 'UPDATE_MASCOTA_DONE';
export const UPDATE_MASCOTA_FAIL = 'UPDATE_MASCOTA_FAIL';

export const DELETE_MASCOTA_INIT = 'DELETE_MASCOTA_INIT';
export const DELETE_MASCOTA_DONE = 'DELETE_MASCOTA_DONE';
export const DELETE_MASCOTA_FAIL = 'DELETE_MASCOTA_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
