import * as types from '../../actions/types/patrimonio/acciones-negocios';

const defaultAccionNegocio = {
  id: null,
  nombre: '',
  tipo_negocio: '',
  porcentaje_participacion: '',
  acuerdos_compra_venta: '',
  telefono: '',
  correo_electronico: '',
  colonia: '',
  ciudad: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  indicaciones: '',
  socios: []
};

const defaultSocio = {
  nombre_completo: ''
};

const initialState = {
  accionNegocio: {...defaultAccionNegocio},
  socio: {...defaultSocio},
  accionesNegocios: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let accionesNegocios = [];

  switch (action.type) {
    case types.GET_ACCIONES_NEGOCIOS_INIT:
      return {
        ...state,
        accionNegocio: {...defaultAccionNegocio},
        socio: {...defaultSocio},
        accionesNegocios: [],
        isGetting: true,
        errors: null
      };
    case types.GET_ACCIONES_NEGOCIOS_DONE:
      return {
        ...state,
        accionesNegocios: [...action.accionesNegocios],
        isGetting: false
      };
    case types.GET_ACCIONES_NEGOCIOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_ACCION_NEGOCIO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.ADD_SOCIO:
      return {
        ...state,
        accionNegocio: {
          ...state.accionNegocio,
          socios: [
            ...state.accionNegocio.socios,
            action.socio
          ]
        }
      };
    case types.RESET_SOCIO:
      return {
        ...state,
        socio: {...defaultSocio}
      };
    case types.REMOVE_SOCIO:
      const socios = [...state.accionNegocio.socios];
      socios.splice(action.index, 1);

      return {
        ...state,
        accionNegocio: {
          ...state.accionNegocio,
          socios
        }
      };
    case types.RESET_ACCION_NEGOCIO_INPUTS:
      return {
        ...state,
        accionNegocio: {...defaultAccionNegocio},
        socio: {...defaultSocio},
        errors: null
      };
    case types.SAVE_ACCION_NEGOCIO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_ACCION_NEGOCIO_DONE:
      return {
        ...state,
        accionNegocio: {...defaultAccionNegocio},
        socio: {...defaultSocio},
        accionesNegocios: [
          ...state.accionesNegocios,
          action.accionNegocio
        ],
        isSaving: false
      };
    case types.SAVE_ACCION_NEGOCIO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_ACCION_NEGOCIO_INPUTS:
      return {
        ...state,
        accionNegocio: {...action.accionNegocio}
      };
    case types.UPDATE_ACCION_NEGOCIO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_ACCION_NEGOCIO_DONE:
      accionesNegocios = state.accionesNegocios.map((accionNegocio) => {
        if (accionNegocio.id === action.accionNegocio.id) {
          return action.accionNegocio;
        }

        return accionNegocio;
      });

      return {
        ...state,
        accionNegocio: {...defaultAccionNegocio},
        socio: {...defaultSocio},
        accionesNegocios,
        isUpdating: false
      };
    case types.UPDATE_ACCION_NEGOCIO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_ACCION_NEGOCIO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_ACCION_NEGOCIO_DONE:
      accionesNegocios = state.accionesNegocios.filter((accionNegocio) => accionNegocio.id !== action.id);

      return {
        ...state,
        accionesNegocios,
        isDeleting: false
      };
    case types.DELETE_ACCION_NEGOCIO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
