export const GET_ADEUDOS_INIT = 'GET_ADEUDOS_INIT';
export const GET_ADEUDOS_DONE = 'GET_ADEUDOS_DONE';
export const GET_ADEUDOS_FAIL = 'GET_ADEUDOS_FAIL';

export const CHANGE_ADEUDO_INPUTS = 'CHANGE_ADEUDO_INPUTS';

export const RESET_ADEUDO_INPUTS = 'RESET_ADEUDO_INPUTS';

export const SAVE_ADEUDO_INIT = 'SAVE_ADEUDO_INIT';
export const SAVE_ADEUDO_DONE = 'SAVE_ADEUDO_DONE';
export const SAVE_ADEUDO_FAIL = 'SAVE_ADEUDO_FAIL';

export const LOAD_ADEUDO_INPUTS = 'LOAD_ADEUDO_INPUTS';

export const UPDATE_ADEUDO_INIT = 'UPDATE_ADEUDO_INIT';
export const UPDATE_ADEUDO_DONE = 'UPDATE_ADEUDO_DONE';
export const UPDATE_ADEUDO_FAIL = 'UPDATE_ADEUDO_FAIL';

export const DELETE_ADEUDO_INIT = 'DELETE_ADEUDO_INIT';
export const DELETE_ADEUDO_DONE = 'DELETE_ADEUDO_DONE';
export const DELETE_ADEUDO_FAIL = 'DELETE_ADEUDO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
