import * as types from '../../actions/types/datos-personales-custodios-red-familiar/custodios';

const defaultCustodio = {
  id: null,
  nombre_completo: '',
  celular: '',
  correo_electronico: '',
  parentesco_id: ''
};

const initialState = {
  custodio: {...defaultCustodio},
  custodios: [],
  parentescos: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let custodios = [];

  switch (action.type) {
    case types.GET_CUSTODIOS_INIT:
      return {
        ...state,
        custodio: {...defaultCustodio},
        custodios: [],
        parentescos: [],
        isGetting: true,
        errors: null
      };
    case types.GET_CUSTODIOS_DONE:
      return {
        ...state,
        custodios: [...action.data.custodios],
        parentescos: [...action.data.parentescos],
        isGetting: false
      };
    case types.GET_CUSTODIOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_CUSTODIO_INPUT:
      return {
        ...state,
        custodio: {
          ...state.custodio,
          [action.attribute]: action.value
        }
      };
    case types.RESET_CUSTODIO_INPUTS:
      return {
        ...state,
        custodio: {...defaultCustodio},
        errors: null
      };
    case types.SAVE_CUSTODIO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_CUSTODIO_DONE:
      return {
        ...state,
        custodio: {...defaultCustodio},
        custodios: [
          ...state.custodios,
          action.custodio
        ],
        isSaving: false
      };
    case types.SAVE_CUSTODIO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.EDIT_CUSTODIO:
      return {
        ...state,
        custodio: {...action.custodio}
      };
    case types.UPDATE_CUSTODIO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_CUSTODIO_DONE:
      custodios = state.custodios.map((custodio) => {
        if (custodio.id === action.custodio.id) {
          return action.custodio;
        }

        return custodio;
      });

      return {
        ...state,
        custodio: {...defaultCustodio},
        custodios,
        isUpdating: false
      };
    case types.UPDATE_CUSTODIO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_CUSTODIO_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      }
    case types.DELETE_CUSTODIO_DONE:
      custodios = state.custodios.filter((custodio) => custodio.id !== action.id);

      return {
        ...state,
        custodios,
        isDeleting: false
      };
    case types.DELETE_CUSTODIO_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
