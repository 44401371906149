export const GET_POLIZAS_SEGUROS_INIT = 'GET_POLIZAS_SEGUROS_INIT';
export const GET_POLIZAS_SEGUROS_DONE = 'GET_POLIZAS_SEGUROS_DONE';
export const GET_POLIZAS_SEGUROS_FAIL = 'GET_POLIZAS_SEGUROS_FAIL';

export const CHANGE_POLIZA_SEGURO_INPUTS = 'CHANGE_POLIZA_SEGURO_INPUTS';

export const ADD_BENEFICIARIO = 'ADD_BENEFICIARIO';
export const RESET_BENEFICIARIO = 'RESET_BENEFICIARIO';
export const REMOVE_BENEFICIARIO = 'REMOVE_BENEFICIARIO';

export const CHANGE_ASESOR_INPUTS = 'CHANGE_ASESOR_INPUTS';

export const RESET_POLIZA_SEGURO_INPUTS = 'RESET_POLIZA_SEGURO_INPUTS';

export const SAVE_POLIZA_SEGURO_INIT = 'SAVE_POLIZA_SEGURO_INIT';
export const SAVE_POLIZA_SEGURO_DONE = 'SAVE_POLIZA_SEGURO_DONE';
export const SAVE_POLIZA_SEGURO_FAIL = 'SAVE_POLIZA_SEGURO_FAIL';

export const LOAD_POLIZA_SEGURO_INPUTS = 'LOAD_POLIZA_SEGURO_INPUTS';

export const UPDATE_POLIZA_SEGURO_INIT = 'UPDATE_POLIZA_SEGURO_INIT';
export const UPDATE_POLIZA_SEGURO_DONE = 'UPDATE_POLIZA_SEGURO_DONE';
export const UPDATE_POLIZA_SEGURO_FAIL = 'UPDATE_POLIZA_SEGURO_FAIL';

export const DELETE_POLIZA_SEGURO_INIT = 'DELETE_POLIZA_SEGURO_INIT';
export const DELETE_POLIZA_SEGURO_DONE = 'DELETE_POLIZA_SEGURO_DONE';
export const DELETE_POLIZA_SEGURO_FAIL = 'DELETE_POLIZA_SEGURO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
