import * as types from '../../actions/types/patrimonio/otros-patrimonios';

const defaultOtroPatrimonio = {
  id: null,
  tipo_valor: '',
  titulo_propiedad: '',
  contacto: '',
  telefono: '',
  correo_electronico: '',
  colonia: '',
  ciudad: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  indicaciones: ''
};

const initialState = {
  otroPatrimonio: {...defaultOtroPatrimonio},
  otrosPatrimonios: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let otrosPatrimonios = [];

  switch (action.type) {
    case types.GET_OTROS_PATRIMONIOS_INIT:
      return {
        ...state,
        otroPatrimonio: {...defaultOtroPatrimonio},
        otrosPatrimonios: [],
        isGetting: true,
        errors: null
      };
    case types.GET_OTROS_PATRIMONIOS_DONE:
      return {
        ...state,
        otrosPatrimonios: [...action.otrosPatrimonios],
        isGetting: false
      };
    case types.GET_OTROS_PATRIMONIOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_OTRO_PATRIMONIO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_OTRO_PATRIMONIO_INPUTS:
      return {
        ...state,
        otroPatrimonio: {...defaultOtroPatrimonio},
        errors: null
      };
    case types.SAVE_OTRO_PATRIMONIO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_OTRO_PATRIMONIO_DONE:
      return {
        ...state,
        otroPatrimonio: {...defaultOtroPatrimonio},
        otrosPatrimonios: [
          ...state.otrosPatrimonios,
          action.otroPatrimonio
        ],
        isSaving: false
      };
    case types.SAVE_OTRO_PATRIMONIO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_OTRO_PATRIMONIO_INPUTS:
      return {
        ...state,
        otroPatrimonio: {...action.otroPatrimonio}
      };
    case types.UPDATE_OTRO_PATRIMONIO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_OTRO_PATRIMONIO_DONE:
      otrosPatrimonios = state.otrosPatrimonios.map((otroPatrimonio) => {
        if (otroPatrimonio.id === action.otroPatrimonio.id) {
          return action.otroPatrimonio;
        }

        return otroPatrimonio;
      });

      return {
        ...state,
        otroPatrimonio: {...defaultOtroPatrimonio},
        otrosPatrimonios,
        isUpdating: false
      };
    case types.UPDATE_OTRO_PATRIMONIO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_OTRO_PATRIMONIO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_OTRO_PATRIMONIO_DONE:
      otrosPatrimonios = state.otrosPatrimonios.filter((otroPatrimonio) => otroPatrimonio.id !== action.id);

      return {
        ...state,
        otrosPatrimonios,
        isDeleting: false
      };
    case types.DELETE_OTRO_PATRIMONIO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
