import * as types from '../../actions/types/datos-personales-custodios-red-familiar/datos-personales';

const defaultPerfil = {
  nombre_completo: '',
  fecha_nacimiento: '',
  lugar_nacimiento: '',
  acta_nacimiento_digital: '',
  acta_nacimiento_fisica: '',
  curp: '',
  rfc: '',
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  has_pareja_conyuge: true
};

const defaultDomicilioFiscal = {
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  is_mismo_domicilio_particular: true
};

const defaultMadre = {
  nombre_completo: '',
  telefono: '',
  correo_electronico: '',
  nacionalidad: '',
  is_vivo: true,
  is_padre: false
};

const defaultPadre = {
  nombre_completo: '',
  telefono: '',
  correo_electronico: '',
  nacionalidad: '',
  is_vivo: true,
  is_padre: true
};

const defaultParejaConyuge = {
  nombre_completo: '',
  telefono: '',
  correo_electronico: '',
  acta_matrimonio_digital: '',
  acta_matrimonio_fisica: '',
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  has_mismo_domicilio: false,
  is_vivo: true
};

const initialState = {
  perfil: {...defaultPerfil},
  domicilioFiscal: {...defaultDomicilioFiscal},
  madre: {...defaultMadre},
  padre: {...defaultPadre},
  parejaConyuge: {...defaultParejaConyuge},
  isGetting: false,
  isSaving: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DATOS_PERSONALES_INIT:
      return {
        ...state,
        perfil: {...defaultPerfil},
        domicilioFiscal: {...defaultDomicilioFiscal},
        madre: {...defaultMadre},
        padre: {...defaultPadre},
        parejaConyuge: {...defaultParejaConyuge},
        isGetting: true,
        errors: null
      };
    case types.GET_DATOS_PERSONALES_DONE:
      return {
        ...state,
        perfil: {
          ...state.perfil,
          ...action.data.perfil
        },
        domicilioFiscal: {
          ...state.domicilioFiscal,
          ...action.data.domicilioFiscal
        },
        madre: {
          ...state.madre,
          ...action.data.madre
        },
        padre: {
          ...state.padre,
          ...action.data.padre
        },
        parejaConyuge: {
          ...state.parejaConyuge,
          ...action.data.parejaConyuge
        },
        isGetting: false
      };
    case types.GET_DATOS_PERSONALES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_DATOS_PERSONALES_INPUT:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.SAVE_DATOS_PERSONALES_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_DATOS_PERSONALES_DONE:
      return {
        ...state,
        perfil: {
          ...state.perfil,
          ...action.data.perfil
        },
        domicilioFiscal: {
          ...state.domicilioFiscal,
          ...action.data.domicilioFiscal
        },
        madre: {
          ...state.madre,
          ...action.data.madre
        },
        padre: {
          ...state.padre,
          ...action.data.padre
        },
        parejaConyuge: {
          ...state.parejaConyuge,
          ...action.data.parejaConyuge
        },
        isSaving: false
      };
    case types.SAVE_DATOS_PERSONALES_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
