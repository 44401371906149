import * as types from '../../actions/types/ultimos-deseos/vida-artificial';

const defaultVidaArtificial = {
  indicaciones: '',
  is_vivir: false,
};

const initialState = {
  vidaArtificial: {...defaultVidaArtificial},
  isGetting: false,
  isSaving: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VIDA_ARTIFICIAL_INIT:
      return {
        ...state,
        vidaArtificial: {...defaultVidaArtificial},
        isGetting: true,
        errors: null
      };
    case types.GET_VIDA_ARTIFICIAL_DONE:
      return {
        ...state,
        vidaArtificial: {
          ...state.vidaArtificial,
          ...action.vidaArtificial
        },
        isGetting: false
      };
    case types.GET_VIDA_ARTIFICIAL_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_VIDA_ARTIFICIAL_INPUT:
      return {
        ...state,
        vidaArtificial: {
          ...state.vidaArtificial,
          [action.attribute]: action.value
        }
      };
    case types.SAVE_VIDA_ARTIFICIAL_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_VIDA_ARTIFICIAL_DONE:
      return {
        ...state,
        vidaArtificial: {
          ...state.vidaArtificial,
          ...action.vidaArtificial
        },
        isSaving: false
      };
    case types.SAVE_VIDA_ARTIFICIAL_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
