export const GET_INVERSIONES_INIT = 'GET_INVERSIONES_INIT';
export const GET_INVERSIONES_DONE = 'GET_INVERSIONES_DONE';
export const GET_INVERSIONES_FAIL = 'GET_INVERSIONES_FAIL';

export const CHANGE_INVERSION_INPUTS = 'CHANGE_INVERSION_INPUTS';

export const ADD_BENEFICIARIO = 'ADD_BENEFICIARIO';
export const RESET_BENEFICIARIO = 'RESET_BENEFICIARIO';
export const REMOVE_BENEFICIARIO = 'REMOVE_BENEFICIARIO';

export const RESET_INVERSION_INPUTS = 'RESET_INVERSION_INPUTS';

export const SAVE_INVERSION_INIT = 'SAVE_INVERSION_INIT';
export const SAVE_INVERSION_DONE = 'SAVE_INVERSION_DONE';
export const SAVE_INVERSION_FAIL = 'SAVE_INVERSION_FAIL';

export const LOAD_INVERSION_INPUTS = 'LOAD_INVERSION_INPUTS';

export const UPDATE_INVERSION_INIT = 'UPDATE_INVERSION_INIT';
export const UPDATE_INVERSION_DONE = 'UPDATE_INVERSION_DONE';
export const UPDATE_INVERSION_FAIL = 'UPDATE_INVERSION_FAIL';

export const DELETE_INVERSION_INIT = 'DELETE_INVERSION_INIT';
export const DELETE_INVERSION_DONE = 'DELETE_INVERSION_DONE';
export const DELETE_INVERSION_FAIL = 'DELETE_INVERSION_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
