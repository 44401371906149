import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import scriptLoader from 'react-async-script-loader';
import * as actions from '../../../actions';
import IconPayPal from '../../UI/Icons/IconPayPal/IconPayPal';
import styles from './PayPal.module.scss';

const PayPal = (props) => {
  const [isLoadingButton, setIsLoadingButton] = React.useState(true);
  const paypalRef = React.useRef();
  const isPayingWithPayPal = useSelector((state) => state.auth.isPayingWithPayPal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const { isScriptLoaded, isScriptLoadSucceed } = props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      window.paypal.Buttons({
        createOrder: async () => {
          try {
            const response = await dispatch(actions.paypalCreateOrder(props.plan));

            return response.data.result.id;
          } catch (error) {
            return null;
          }
        },
        onApprove: async (data) => {
          return await dispatch(actions.paypalApproveOrder(data.orderID, { ...props.registeredUser, plan_id: props.plan }));
        },
        onError: () => {}
      }).render(paypalRef.current).then(() => setIsLoadingButton(false));
    }

    // eslint-disable-next-line
  }, [props.isScriptLoaded, props.isScriptLoadSucceed]);

  return (
    <>
      <div className={classNames(styles.Loading, { [styles.Show]: isLoadingButton })}>Cargando botón de <IconPayPal height="24px" /></div>
      <div className={classNames(styles.PayPal, { [styles.Hide]: isPayingWithPayPal || isLoadingButton })} ref={paypalRef}></div>
      <div className={classNames(styles.Loading, { [styles.Show]: isPayingWithPayPal })}>Registrándote…</div>
    </>
  );
};

PayPal.propTypes = {
  plan: PropTypes.number,
  registeredUser: PropTypes.object.isRequired
};

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=AdDtaDJTTu1LRDeYg-0I_e3BL1mwoFQHn89uiTIbaIVSmmt3jMt_FplyADZZCqsU2zH6Ggm-39ink4jy&disable-funding=credit,card&currency=MXN`)(PayPal);
