export const GET_CUSTODIOS_INIT = 'GET_CUSTODIOS_INIT';
export const GET_CUSTODIOS_DONE = 'GET_CUSTODIOS_DONE';
export const GET_CUSTODIOS_FAIL = 'GET_CUSTODIOS_FAIL';

export const CHANGE_CUSTODIO_INPUT = 'CHANGE_CUSTODIO_INPUT';

export const RESET_CUSTODIO_INPUTS = 'RESET_CUSTODIO_INPUTS';

export const SAVE_CUSTODIO_INIT = 'SAVE_CUSTODIO_INIT';
export const SAVE_CUSTODIO_DONE = 'SAVE_CUSTODIO_DONE';
export const SAVE_CUSTODIO_FAIL = 'SAVE_CUSTODIO_FAIL';

export const EDIT_CUSTODIO = 'EDIT_CUSTODIO';

export const UPDATE_CUSTODIO_INIT = 'UPDATE_CUSTODIO_INIT';
export const UPDATE_CUSTODIO_DONE = 'UPDATE_CUSTODIO_DONE';
export const UPDATE_CUSTODIO_FAIL = 'UPDATE_CUSTODIO_FAIL';

export const DELETE_CUSTODIO_INIT = 'DELETE_CUSTODIO_INIT';
export const DELETE_CUSTODIO_DONE = 'DELETE_CUSTODIO_DONE';
export const DELETE_CUSTODIO_FAIL = 'DELETE_CUSTODIO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
