import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../Button/Button';
import styles from './Slider.module.scss';

const Slider = (props) => (
  <Carousel {...props} className={styles.Slider}>
    {React.Children.map(props.children, (child) => (
      React.cloneElement(child, { className:  styles.Item })
    ))}
  </Carousel>
);

const Image = (props) => {
  const imageStyles = {
    backgroundImage: `url(${props.src})`
  };

  return (
    <div className={styles.Image} style={imageStyles} />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired
};

const Caption = (props) => (
  <Carousel.Caption className={styles.Caption}>
    <Container className="h-100">
      <Row className="h-100 align-items-center">
        <Col lg={7}>
          {props.children}
        </Col>
      </Row>
    </Container>
  </Carousel.Caption>
);

const Title = (props) => (
  <h2 className={styles.Title}>{props.children}</h2>
);

const Paragraph = (props) => (
  <p className={styles.Paragraph}>{props.children}</p>
);

const Link = (props) => (
  <Button link variant="primary" to={props.to}>{props.children}</Button>
);

Link.propTypes = {
  to: PropTypes.string.isRequired
};

Slider.Item = Carousel.Item;
Slider.Image = Image;
Slider.Caption = Caption;
Slider.Title = Title;
Slider.Paragraph = Paragraph;
Slider.Link = Link;

export default Slider;
