import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Title.module.scss';

const Title = (props) => {
  const classes = classNames(styles.Title, {
    [styles.Center]: props.center
  });

  return (
    <div className={classes}>
      {props.quote && (<h3 className={styles.Quote}>{props.quote}</h3>)}
      <h2 className={styles.Text}>{props.text}</h2>
    </div>
  );
};

Title.propTypes = {
  center: PropTypes.bool,
  quote: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default Title;
