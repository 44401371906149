import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Dialog from '../../UI/Landing/Dialog/Dialog';

const PoliticaPrivacidad = (props) => {

  return ReactDOM.createPortal(
    <Dialog
      title="Política de privacidad"
      show={props.show}
      onHide={props.onHide}
    >
      <p>Alberto Molina Diz (en lo sucesivo La Administración), persona física con actividad empresarial, con domicilio en Calle 20 número 33 Colonia San Jose Vista Hermosa, C.P. 72190 en Puebla, Puebla, México es responsable de la web www.porsinotevuelvoaver.com cuyo fin es el de ofrecer un servicio de almacenamiento digital seguro y discreto de las últimas voluntades en caso de una posible incapacidad mental o defunción.</p>
      <p>La utilización de la página web le atribuye la condición de usuario de la misma (en adelante “el Usuario”) e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en la presente política de privacidad así como de los Términos y Condiciones de Uso publicados por La Administración en el momento en que el Usuario acceda a la web.</p>
      <p>La presente Política de Privacidad establece los términos en que usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web.</p>
      <p>La Administración se reserva el derecho a modificar la presente política de privacidad para adecuarla a las novedades legales o jurisprudenciales. En dichos supuestos se anunciarán en esta página los cambios introducidos con razonable antelación a su puesta en práctica. La utilización de nuestros servicios una vez comunicado este cambio implicará la aceptación de estos.</p>
      <h4>Legislación</h4>
      <p>La Administración cumple en el tratamiento de los datos personales de sus usuarios con la legislación vigente en México, en particular, con la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES. Para ello adopta las medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados, habida cuenta del estado de la tecnología, la naturaleza de los datos y los riesgos a los que están expuestos.</p>
      <h4>Seguridad</h4>
      <p>La Administración ha adoptado los niveles de Seguridad de protección de los datos personales, además, procura instalar aquellos otros medios y medidas técnicas adicionales para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos y archivos personales facilitados.</p>
      <p>Es importante considerar que toda transferencia de datos a través de Internet tiene el riesgo de ser interceptada, por lo que, de llegar a ocurrir, La Administración no se hace responsable y la navegación es bajo riesgo del Usuario. Sin embargo, con la intención de evitar este tipo de eventos la web dispone de un certificado de seguridad que se utiliza por el protocolo SSL (Secure Socket Layer), para realizar conexiones seguras entre el Usuario y  www.porsinotevuelvoaver.com. En este proceso se establecen varios parámetros para realizar la conexión de forma segura, y se establece usando llaves preestablecidas, se codifica y descodifica todo lo que sea enviado hasta que la conexión se cierre.</p>
      <p>En aquellos servicios de la web que precisen registrarse como Usuario se deberá elegir una contraseña. El Usuario es responsable de mantener la confidencialidad de esta contraseña, así como de todas las actividades que ocurran en la sesión iniciada con su nombre de usuario y contraseña. El Usuario se compromete a notificar a la Administración a la mayor brevedad el uso no autorizado de su nombre de usuario y contraseña o cualquier otro fallo en la seguridad. La Administración no será responsable por los daños o pérdidas que se pudieran originar debido al no cumplimiento de esta obligación por parte del Usuario.</p>
      <h4>Obtención y uso de la información</h4>
      <p>La Administración utilizará tus datos personales con la única finalidad de posibilitar tu participación en todos los servicios de la web.</p>
      <p>La Administración no cederá los datos personales de los usuarios a terceros sin solicitar previamente su consentimiento informando del receptor de esos datos y la finalidad de esa posible cesión.</p>
      <p>El envío de los datos personales, incluido el correo electrónico, supone la aceptación expresa del Usuario a su inclusión en un fichero automatizado del que es responsable la Administración.</p>
      <p>Determinados servicios dentro de la web www.porsinotevuelvoaver.com requieren un registro, por lo que el Usuario debe crear una cuenta en la web, proporcionando la siguiente información: Dirección de correo electrónico, nombre de usuario, sexo, edad, declaración y mayoría de edad y contraseña.</p>
      <p>Dependiendo del servicio contratado, también es posible incluir archivos de documento, imagen, sonido o vídeo, en su área personal, y designar custodios con: nombres, teléfonos y correos electrónicos.</p>
      <h4>Comunicaciones</h4>
      <p>La Administración, como responsable de la web www.porsinotevuelvoaver.com se comunicará con el Usuario a través de correos electrónicos a la dirección de e-mail incluida en sus datos de registro en aquellos casos en los que sea preciso con motivo de la correcta prestación del servicio.</p>
      <h4>Menores</h4>
      <p>Los servicios aquí descritos están dirigidos a mayores de 18 años. Los menores de esta edad no están autorizados bajo ningún concepto a utilizar nuestros servicios.</p>
      <h4>Direcciones IP y cookies</h4>
      <p>Debido a los protocolos de comunicación existentes en Internet, cuando el Usuario visita nuestra página web, la Administración recibe automáticamente la dirección IP (Internet Protocol) que le ha sido asignada a su ordenador por su Proveedor de Acceso. El registro de dicha dirección IP sirve sólo para fines exclusivamente internos, como son las estadísticas de acceso a este sitio web. La dirección IP para un mismo usuario es distinta de una conexión a Internet a otra, con lo que no es posible rastrear los hábitos de navegación a través de la web por un determinado usuario.</p>
      <p>La Administración puede utilizar cookies cuando un usuario navega por sus sitios y páginas web como www.porsinotevuelvoaver.com.</p>
      <p>Las cookies que se puedan utilizar en la web se asocian únicamente con el navegador de un ordenador determinado (un usuario anónimo), y no proporcionan por sí mismas ningún dato personal del usuario. Las cookies sirven sólo para fines exclusivamente internos, como son las estadísticas de acceso a la web. Las cookies utilizadas no pueden leer los archivos cookies creados por otros proveedores o sitios web. El Usuario tiene la posibilidad de configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en su disco duro. El Usuario puede consultar las instrucciones y manuales de su navegador para ampliar esta información.</p>
      <h4>Enlaces a terceros</h4>
      <p>Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
      <h4>Control de la información personal</h4>
      <p>En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.  Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico.  En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>
      <p>La Administración no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
      <p>La Administración se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
      <h4>Modificación y cancelación de datos</h4>
      <p>El Usuario que desee modificar sus datos personales, o dejar de recibir nuestros servicios, cancelando su registro, puede corregir, actualizar o borrar sus datos personales y cuenta fácilmente en el Área de Registro.</p>
    </Dialog>,
    document.getElementById('modal')
  );
};

PoliticaPrivacidad.defaultProps = {
  show: false
};

PoliticaPrivacidad.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default PoliticaPrivacidad;
