export const GET_BIENES_INMUEBLES_INIT = 'GET_BIENES_INMUEBLES_INIT';
export const GET_BIENES_INMUEBLES_DONE = 'GET_BIENES_INMUEBLES_DONE';
export const GET_BIENES_INMUEBLES_FAIL = 'GET_BIENES_INMUEBLES_FAIL';

export const CHANGE_BIEN_INMUEBLE_INPUTS = 'CHANGE_BIEN_INMUEBLE_INPUTS';

export const RESET_BIEN_INMUEBLE_INPUTS = 'RESET_BIEN_INMUEBLE_INPUTS';

export const SAVE_BIEN_INMUEBLE_INIT = 'SAVE_BIEN_INMUEBLE_INIT';
export const SAVE_BIEN_INMUEBLE_DONE = 'SAVE_BIEN_INMUEBLE_DONE';
export const SAVE_BIEN_INMUEBLE_FAIL = 'SAVE_BIEN_INMUEBLE_FAIL';

export const LOAD_BIEN_INMUEBLE_INPUTS = 'LOAD_BIEN_INMUEBLE_INPUTS';

export const UPDATE_BIEN_INMUEBLE_INIT = 'UPDATE_BIEN_INMUEBLE_INIT';
export const UPDATE_BIEN_INMUEBLE_DONE = 'UPDATE_BIEN_INMUEBLE_DONE';
export const UPDATE_BIEN_INMUEBLE_FAIL = 'UPDATE_BIEN_INMUEBLE_FAIL';

export const DELETE_BIEN_INMUEBLE_INIT = 'DELETE_BIEN_INMUEBLE_INIT';
export const DELETE_BIEN_INMUEBLE_DONE = 'DELETE_BIEN_INMUEBLE_DONE';
export const DELETE_BIEN_INMUEBLE_FAIL = 'DELETE_BIEN_INMUEBLE_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
