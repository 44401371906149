import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Title from '../Title/Title';
import Overlap from '../../UI/Landing/Overlap/Overlap';
import Timeline from '../../UI/Landing/Timeline/Timeline';
import IconMale from '../../UI/Icons/IconMale/IconMale';
import IconChecklist from '../../UI/Icons/IconChecklist/IconChecklist';
import IconPencil from '../../UI/Icons/IconPencil/IconPencil';
import IconPhone from '../../UI/Icons/IconPhone/IconPhone';
import IconBox from '../../UI/Icons/IconBox/IconBox';
import Background from '../../UI/Landing/Backgroud/Background';
import styles from './ComoFunciona.module.scss';

const ComoFunciona = () => {
  const [frontImage, setFrontImage] = React.useState({ src: '', alt: '' });
  const [backImage, setBackImage] = React.useState({ src: '', alt: '' });

  const updateImage = (point) => {
    switch (point) {
      case '1':
        setFrontImage({ src: require('../../../assets/images/crea-tu-perfil-delante.jpg'), alt: 'Crea tu perfil' });
        setBackImage({ src: require('../../../assets/images/crea-tu-perfil-detras.jpg'), alt: 'Crea tu perfil' });
        break;
      case '2':
        setFrontImage({ src: require('../../../assets/images/escoge-un-plan-delante.jpg'), alt: 'Escoge un plan' });
        setBackImage({ src: require('../../../assets/images/escoge-un-plan-detras.jpg'), alt: 'Escoge un plan' });
        break;
      case '3':
        setFrontImage({ src: require('../../../assets/images/crea-tu-legado-delante.jpg'), alt: 'Crea tu legado' });
        setBackImage({ src: require('../../../assets/images/crea-tu-legado-detras.jpg'), alt: 'Crea tu legado' });
        break;
      case '4':
        setFrontImage({ src: require('../../../assets/images/prueba-de-vida-delante.jpg'), alt: 'Prueba de vida' });
        setBackImage({ src: require('../../../assets/images/prueba-de-vida-detras.jpg'), alt: 'Prueba de vida' });
        break;
      case '5':
        setFrontImage({ src: require('../../../assets/images/entrega-delante.jpg'), alt: 'Entrega' });
        setBackImage({ src: require('../../../assets/images/entrega-detras.jpg'), alt: 'Entrega' });
        break;
      default:
        break;
    }
  };

  return (
    <Background>
      <Container>
        <Row>
          <Col lg={6} className={styles.Overlap}>
            <Overlap>
              <Overlap.Front {...frontImage} />
              <Overlap.Back {...backImage} />
            </Overlap>
          </Col>
          <Col lg={6}>
            <Row>
              <Col>
                <Title quote="Por si no te vuelvo a ver" text="¿Cómo funciona?" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Timeline defaultActivePoint="1" onActivePoint={(point) => updateImage(point)}>
                  <Timeline.Block activePoint="1">
                    <Timeline.Point activePoint="1">
                      {(props) => <IconMale {...props} />}
                    </Timeline.Point>
                    <Timeline.Content>
                      <Timeline.Title>1. Crea tu perfil</Timeline.Title>
                      <Timeline.Paragraph>Registrate en el sistema.</Timeline.Paragraph>
                    </Timeline.Content>
                  </Timeline.Block>
                  <Timeline.Block activePoint="2">
                    <Timeline.Point activePoint="2">
                      {(props) => <IconChecklist {...props} />}
                    </Timeline.Point>
                    <Timeline.Content>
                      <Timeline.Title>2. Escoge un plan</Timeline.Title>
                      <Timeline.Paragraph>Escoge una de las diferentes opciones que te presentamos y da de alta a tus custodios y contactos.</Timeline.Paragraph>
                    </Timeline.Content>
                  </Timeline.Block>
                  <Timeline.Block activePoint="3">
                    <Timeline.Point activePoint="3">
                      {(props) => <IconPencil {...props} />}
                    </Timeline.Point>
                    <Timeline.Content>
                      <Timeline.Title>3. Crea tu legado</Timeline.Title>
                      <Timeline.Paragraph>Escribe la biografía de tu vida, mensajes póstumos y organiza la distribución de tus bienes virtuales y materiales, así como última voluntad y deseos.</Timeline.Paragraph>
                    </Timeline.Content>
                  </Timeline.Block>
                  <Timeline.Block activePoint="4">
                    <Timeline.Point activePoint="4">
                      {(props) => <IconPhone {...props} />}
                    </Timeline.Point>
                    <Timeline.Content>
                      <Timeline.Title>4. Prueba de vida</Timeline.Title>
                      <Timeline.Paragraph>Estaremos en contacto contigo para comprobar tu estado por medios electrónicos.</Timeline.Paragraph>
                    </Timeline.Content>
                  </Timeline.Block>
                  <Timeline.Block activePoint="5">
                    <Timeline.Point activePoint="5">
                      {(props) => <IconBox {...props} />}
                    </Timeline.Point>
                    <Timeline.Content>
                      <Timeline.Title>5. Entrega</Timeline.Title>
                      <Timeline.Paragraph>En caso de fallecimiento o ausencia se entregará la información.</Timeline.Paragraph>
                    </Timeline.Content>
                  </Timeline.Block>
                </Timeline>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Background>
  );
};

export default ComoFunciona;
