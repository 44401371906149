export const patrimonioCategorias = {
  ID: {
    INVERSIONES: 1,
    ADEUDOS: 2,
    VEHICULOS: 3,
    BIENES_INMUEBLES: 4,
    ACCIONES_NEGOCIOS: 5,
    BIENES_VALOR_SENTIMENTAL: 6,
    OTROS_PATRIMONIOS: 7
  },
  NAME: {
    1: 'Inversiones, casas de bolsa y cuentas bancarias',
    2: 'Adeudos',
    3: 'Vehículos',
    4: 'Bienes inmuebles',
    5: 'Acciones o negocios',
    6: 'Bienes con valor sentimental',
    7: 'Otro'
  }
};
