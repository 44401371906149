export const GET_VEHICULOS_INIT = 'GET_VEHICULOS_INIT';
export const GET_VEHICULOS_DONE = 'GET_VEHICULOS_DONE';
export const GET_VEHICULOS_FAIL = 'GET_VEHICULOS_FAIL';

export const CHANGE_VEHICULO_INPUTS = 'CHANGE_VEHICULO_INPUTS';

export const RESET_VEHICULO_INPUTS = 'RESET_VEHICULO_INPUTS';

export const SAVE_VEHICULO_INIT = 'SAVE_VEHICULO_INIT';
export const SAVE_VEHICULO_DONE = 'SAVE_VEHICULO_DONE';
export const SAVE_VEHICULO_FAIL = 'SAVE_VEHICULO_FAIL';

export const LOAD_VEHICULO_INPUTS = 'LOAD_VEHICULO_INPUTS';

export const UPDATE_VEHICULO_INIT = 'UPDATE_VEHICULO_INIT';
export const UPDATE_VEHICULO_DONE = 'UPDATE_VEHICULO_DONE';
export const UPDATE_VEHICULO_FAIL = 'UPDATE_VEHICULO_FAIL';

export const DELETE_VEHICULO_INIT = 'DELETE_VEHICULO_INIT';
export const DELETE_VEHICULO_DONE = 'DELETE_VEHICULO_DONE';
export const DELETE_VEHICULO_FAIL = 'DELETE_VEHICULO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
