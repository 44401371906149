import React from 'react';

const IconMenu = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
		<path d="m12.03 84.212h360.91c6.641 0 12.03-5.39 12.03-12.03 0-6.641-5.39-12.03-12.03-12.03h-360.91c-6.64 0-12.03 5.389-12.03 12.03s5.39 12.03 12.03 12.03z" />
		<path d="m372.94 180.46h-360.91c-6.641 0-12.03 5.39-12.03 12.03s5.39 12.03 12.03 12.03h360.91c6.641 0 12.03-5.39 12.03-12.03s-5.389-12.03-12.03-12.03z" />
		<path d="m372.94 300.76h-360.91c-6.641 0-12.03 5.39-12.03 12.03 0 6.641 5.39 12.03 12.03 12.03h360.91c6.641 0 12.03-5.39 12.03-12.03 1e-3 -6.641-5.389-12.03-12.03-12.03z" />
  </svg>
);

export default IconMenu;
