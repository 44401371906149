import React from 'react';

function useElementSize() {
  const ref = React.useRef();
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    function updateSize() {
      const { width, height } = ref.current.getBoundingClientRect();

      setSize({ width, height });
    }
    
    updateSize();
    
    ref.current.addEventListener('resize', updateSize);

    return () => {
      // eslint-disable-next-line
      ref.current.removeEventListener('resize', updateSize);
    };

    // eslint-disable-next-line
  }, [ref.current]);

  return [ref, size];
};

export default useElementSize;
