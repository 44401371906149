import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Layout from '../../components/Auth/Layout/Layout';
import TogglePassword from '../../components/Auth/TogglePassword/TogglePassword';
import Button from '../../components/UI/Button/Button';
import * as actions from '../../actions';
import * as helpers from '../../helpers';

const Login = () => {
  const [togglePassword, setTogglePassword] = React.useState(false);
  const errors = useSelector((state) => state.auth.errors);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('El campo "Correo electrónico" debe ser una dirección de correo válida.')
        .required('El campo "Correo electrónico" es obligatorio.'),
      password: Yup.string().required('El campo "Contraseña" es obligatorio.')
    }),
    onSubmit: (values) => dispatch(actions.login(values))
  });

  const onTogglePassword = (value) => {
    setTogglePassword(value);
  };

  React.useEffect(() => {
    if (helpers.isObject(errors)) {
      formik.setErrors(errors);
      formik.setSubmitting(false);
    }

    // eslint-disable-next-line
  }, [errors]);

  React.useEffect(() => {
    return () => {
      setTogglePassword(false);
      formik.resetForm({});
      formik.setSubmitting(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {(styles) => (
        <>
          <h2 className={styles.Title}>Ingresa a tu cuenta</h2>
          <Form className={styles.Form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Form.Group className={styles.Group} controlId="email">
            <Form.Label className={styles.Label}>Correo electrónico</Form.Label>
            <Form.Control
              type="email"
              name="email"
              className={styles.Control}
              autoFocus
              isInvalid={formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.errors.email && formik.touched.email && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
            </Form.Group>
            <Form.Group className={styles.Group} controlId="password">
              <Form.Label className={classNames(styles.Label, styles.Password)}>
                Contraseña <TogglePassword onToggle={onTogglePassword} />
              </Form.Label>
              <Form.Control
                type={togglePassword ? 'text' : 'password'}
                name="password"
                className={styles.Control}
                isInvalid={formik.errors.password && formik.touched.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              className={styles.Button}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Ingresando...' : 'Ingresar'} 
            </Button>
          </Form>
        </>
      )}
    </Layout>
  );
};

export default Login;
