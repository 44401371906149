import * as types from '../actions/types/auth';

const defaultRegisteredUser = {
  nombre_completo: '',
  celular: '',
  email: '',
  password: '',
  politicas_privacidad: false,
  terminos_condiciones: false
};

const initialState = {
  token: null,
  user: {},
  isAuthenticated: false,
  registeredUser: { ...defaultRegisteredUser },
  isValidRegisteredUser: false,
  isRegisteringFreePlan: false,
  isPayingWithPayPal: false,
  isPayingWithCreditCard: false,
  ticket: '',
  isPayingWithOxxo: false,
  isPaidWithOxxo: false,
  isPaid: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_START:
      return {
        ...state,
        token: null,
        user: {},
        errors: null
      };
    case types.LOGIN_DONE:
      return {
        ...state,
        token: action.token,
        user: action.user
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        errors: action.errors
      };

    case types.STORE_REGISTERED_USER_START:
      return {
        ...state,
        isValidRegisteredUser: false,
        errors: null
      };
    case types.STORE_REGISTERED_USER_DONE:
      return {
        ...state,
        registeredUser: action.registeredUser,
        isValidRegisteredUser: true
      };
    case types.STORE_REGISTERED_USER_FAIL:
      return {
        ...state,
        errors: action.errors
      };

    case types.RESET_REGISTERED_USER:
      return {
        ...state,
        registeredUser: { ...defaultRegisteredUser },
        isValidRegisteredUser: false,
        ticket: '',
        isPaidWithOxxo: false
      };

    case types.REGISTER_FREE_PLAN_START:
      return {
        ...state,
        token: null,
        user: {},
        isRegisteringFreePlan: true
      };
    case types.REGISTER_FREE_PLAN_DONE:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isRegisteringFreePlan: false
      };
    case types.REGISTER_FREE_PLAN_FAIL:
      return {
        ...state,
        isRegisteringFreePlan: false,
        errors: action.errors
      };

    case types.PAYPAL_CREATE_ORDER_START:
      return {
        ...state,
        errors: null
      };
    case types.PAYPAL_CREATE_ORDER_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.PAYPAL_APPROVE_ORDER_START:
      return {
        ...state,
        token: null,
        user: {},
        isPayingWithPayPal: true,
        errors: null
      };
    case types.PAYPAL_APPROVE_ORDER_DONE:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isPayingWithPayPal: false
      };
    case types.PAYPAL_APPROVE_ORDER_FAIL:
      return {
        ...state,
        isPayingWithPayPal: false,
        errors: action.errors
      };

    case types.CREDIT_CARD_PAY_ORDER_START:
      return {
        ...state,
        token: null,
        user: {},
        isPayingWithCreditCard: true,
        errors: null
      };
    case types.CREDIT_CARD_PAY_ORDER_DONE:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isPayingWithCreditCard: false
      };
    case types.CREDIT_CARD_PAY_ORDER_FAIL:
      return {
        ...state,
        isPayingWithCreditCard: false,
        errors: action.errors
      };

    case types.OXXO_PAY_ORDER_START:
      return {
        ...state,
        ticket: '',
        isPayingWithOxxo: true,
        isPaidWithOxxo: false,
        errors: null
      };
    case types.OXXO_PAY_ORDER_DONE:
      return {
        ...state,
        ticket: action.ticket,
        isPayingWithOxxo: false,
        isPaidWithOxxo: true
      };
    case types.OXXO_PAY_ORDER_FAIL:
      return {
        ...state,
        isPayingWithOxxo: false,
        errors: action.errors
      };

    case types.LOGOUT:
      return {
        ...state,
        token: null,
        user: {},
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
