import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-scroll';

const Target = (props) => {
  const classes = classNames('nav-link', props.className);

  return (
    <Link className={classes} activeClass={props.activeClass} smooth={true} spy={true} to={props.to}>
      {props.children}
    </Link>
  );
};

Target.propTypes = {
  className: PropTypes.string.isRequired,
  activeClass: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default Target;
