import * as types from '../actions/types/menu-principal';

const defaultMenuPrincipal = {
  isCompletedDatosPersonalesCustodiosRedFamiliar: false,
  isCompletedAsesoresContactos: false,
  isCompletedPolizasSeguros: false,
  isCompletedPatrimonio: false,
  isCompletedEmpleoPension: false,
  isCompletedUltimosDeseos: false,
  isCompletedMascotas: false
};

const initialState = {
  menuPrincipal: {...defaultMenuPrincipal},
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MENU_PRINCIPAL_INIT:
      return {
        ...state,
        menuPrincipal: {...defaultMenuPrincipal},
        isGetting: true,
        errors: null
      };
    case types.GET_MENU_PRINCIPAL_DONE:
      return {
        ...state,
        menuPrincipal: {...action.data},
        isGetting: false
      };
    case types.GET_MENU_PRINCIPAL_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default reducer;
