import * as types from '../../actions/types/patrimonio/bienes-valores-sentimentales';

const defaultBienValorSentimental = {
  id: null,
  objeto: '',
  ubicacion_fisica: '',
  destino_final: '',
};

const initialState = {
  bienValorSentimental: {...defaultBienValorSentimental},
  bienesValoresSentimentales: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let bienesValoresSentimentales = [];

  switch (action.type) {
    case types.GET_BIENES_VALORES_SENTIMENTALES_INIT:
      return {
        ...state,
        bienValorSentimental: {...defaultBienValorSentimental},
        bienesValoresSentimentales: [],
        isGetting: true,
        errors: null
      };
    case types.GET_BIENES_VALORES_SENTIMENTALES_DONE:
      return {
        ...state,
        bienesValoresSentimentales: [...action.bienesValoresSentimentales],
        isGetting: false
      };
    case types.GET_BIENES_VALORES_SENTIMENTALES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_BIEN_VALOR_SENTIMENTAL_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_BIEN_VALOR_SENTIMENTAL_INPUTS:
      return {
        ...state,
        bienValorSentimental: {...defaultBienValorSentimental},
        errors: null
      };
    case types.SAVE_BIEN_VALOR_SENTIMENTAL_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_BIEN_VALOR_SENTIMENTAL_DONE:
      return {
        ...state,
        bienValorSentimental: {...defaultBienValorSentimental},
        bienesValoresSentimentales: [
          ...state.bienesValoresSentimentales,
          action.bienValorSentimental
        ],
        isSaving: false
      };
    case types.SAVE_BIEN_VALOR_SENTIMENTAL_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_BIEN_VALOR_SENTIMENTAL_INPUTS:
      return {
        ...state,
        bienValorSentimental: {...action.bienValorSentimental}
      };
    case types.UPDATE_BIEN_VALOR_SENTIMENTAL_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_BIEN_VALOR_SENTIMENTAL_DONE:
      bienesValoresSentimentales = state.bienesValoresSentimentales.map((bienValorSentimental) => {
        if (bienValorSentimental.id === action.bienValorSentimental.id) {
          return action.bienValorSentimental;
        }

        return bienValorSentimental;
      });

      return {
        ...state,
        bienValorSentimental: {...defaultBienValorSentimental},
        bienesValoresSentimentales,
        isUpdating: false
      };
    case types.UPDATE_BIEN_VALOR_SENTIMENTAL_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_BIEN_VALOR_SENTIMENTAL_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_BIEN_VALOR_SENTIMENTAL_DONE:
      bienesValoresSentimentales = state.bienesValoresSentimentales.filter((bienValorSentimental) => bienValorSentimental.id !== action.id);

      return {
        ...state,
        bienesValoresSentimentales,
        isDeleting: false
      };
    case types.DELETE_BIEN_VALOR_SENTIMENTAL_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
