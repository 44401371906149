import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteGuard = ({ redirect, passes, children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => (
      passes ? (
          children
        ) : (
          <Redirect to={redirect} />
        )
    )}
  />
);

RouteGuard.defaultProps = {
  redirect: '/',
  passes: false
};

RouteGuard.propTypes = {
  redirect: PropTypes.string.isRequired,
  passes: PropTypes.bool.isRequired
};

export default RouteGuard;
