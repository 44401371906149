import * as types from '../actions/types/asesores-contactos';

const defaultAsesorContacto = {
  id: null,
  tipo: 1,
  nombre_completo: '',
  telefono_particular: '',
  telefono_oficina: '',
  correo_electronico: '',
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: ''
};

const initialState = {
  asesorContacto: {...defaultAsesorContacto},
  asesoresContactos: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let asesoresContactos = [];

  switch (action.type) {
    case types.GET_ASESORES_CONTACTOS_INIT:
      return {
        ...state,
        asesorContacto: {...defaultAsesorContacto},
        asesoresContactos: [],
        isGetting: true,
        errors: null
      };
    case types.GET_ASESORES_CONTACTOS_DONE:
      return {
        ...state,
        asesoresContactos: [...action.asesoresContactos],
        isGetting: false
      };
    case types.GET_ASESORES_CONTACTOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_ASESOR_CONTACTO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_ASESOR_CONTACTO_INPUTS:
      return {
        ...state,
        asesorContacto: {...defaultAsesorContacto},
        errors: null
      };
    case types.SAVE_ASESOR_CONTACTO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_ASESOR_CONTACTO_DONE:
      return {
        ...state,
        asesorContacto: {...defaultAsesorContacto},
        asesoresContactos: [
          ...state.asesoresContactos,
          action.asesorContacto
        ],
        isSaving: false
      };
    case types.SAVE_ASESOR_CONTACTO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_ASESOR_CONTACTO_INPUTS:
      return {
        ...state,
        asesorContacto: {...action.asesorContacto}
      };
    case types.UPDATE_ASESOR_CONTACTO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_ASESOR_CONTACTO_DONE:
      asesoresContactos = state.asesoresContactos.map((asesorContacto) => {
        if (asesorContacto.id === action.asesorContacto.id) {
          return action.asesorContacto;
        }

        return asesorContacto;
      });

      return {
        ...state,
        asesorContacto: {...defaultAsesorContacto},
        asesoresContactos,
        isUpdating: false
      };
    case types.UPDATE_ASESOR_CONTACTO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_ASESOR_CONTACTO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_ASESOR_CONTACTO_DONE:
      asesoresContactos = state.asesoresContactos.filter((asesorContacto) => asesorContacto.id !== action.id);

      return {
        ...state,
        asesoresContactos,
        isDeleting: false
      };
    case types.DELETE_ASESOR_CONTACTO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
