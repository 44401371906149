import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Target from './Target/Target';
import IconMenu from '../../UI/Icons/IconMenu/IconMenu';
import Button from '../../UI/Button/Button';
import logotipo from '../../../assets/images/logotipo.png';
import styles from './Navigation.module.scss';

const Navigation = (props) => (
  <Navbar sticky="top" expand="lg" className={styles.Navbar} ref={props.navbarRef}>
    <Container>
      <Link className={classNames('navbar-brand', styles.Logo)} to="/">
        <img src={logotipo} className={styles.Image} alt="Logotipo de Por si no te vuelvo a vers" />
      </Link>
      <Navbar.Toggle className={styles.Toggle}>
        <IconMenu viewBox="0 0 384.97 384.97" height={26} className={styles.Icon} />
      </Navbar.Toggle>
      <Navbar.Collapse id="landing-navbar">
        <Nav className="ml-auto">
          <Target className={styles.Link} activeClass={styles.isActive} to="inicio">Inicio</Target>
          <Target className={styles.Link} activeClass={styles.isActive} to="como-funciona">¿Cómo funciona?</Target>
          <Target className={styles.Link} activeClass={styles.isActive} to="planes">Planes</Target>
          <Target className={styles.Link} activeClass={styles.isActive} to="ayuda">Ayuda</Target>
        </Nav>
        <Nav className={`${styles.Buttons} ml-auto`}>
          <Button link variant="primary" small className={styles.Register} to="/registrarse">Registrate gratis</Button>
          <Button link variant="primary" ghost small className={styles.Login} to="/iniciar-sesion">Iniciar sesión</Button>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Navigation;
