import React from 'react';
import IconEyeShow from '../../UI/Icons/IconEyeShow/IconEyeShow';
import IconEyeHide from '../../UI/Icons/IconEyeHide/IconEyeHide';
import styles from './TogglePassword.module.scss';

const TogglePassword = (props) => {
  const [toggle, setToggle] = React.useState(true);

  const onToggle = () => {
    setToggle(!toggle);
    props.onToggle(toggle);
  };

  return (
    <span className={styles.TogglePassword} onClick={onToggle}>
      {toggle ? (
        <><IconEyeShow height="15px" className={styles.Icon} /> Mostrar</>
      ) : (
        <><IconEyeHide height="15px" className={styles.Icon} /> Ocultar</>
      )}
    </span>
  );
};

export default TogglePassword;
