export const creditCardErrors = {
  '205': { key: 'cardNumber', value: 'Ingresa el número de tu tarjeta.' },
  '208': { key: 'cardExpirationMonth', value: 'Ingresa el mes.' },
  '209': { key: 'cardExpirationYear', value: 'Ingresa el año.' },
  '212': { key: 'docType', value: 'Ingresa tu tipo de documento.' },
  '213': { key: 'cardholder.document.subtype', value: 'Ingresa tu documento.' },
  '214': { key: 'docNumber', value: 'Ingresa tu documento.' },
  '220': { key: 'cardIssuerId', value: 'Ingresa tu banco.' },
  '221': { key: 'cardholderName', value: 'Ingresa el nombre y apellido.' },
  '224': { key: 'securityCode', value: 'Ingresa el código de seguridad.' },
  'E301':	{ key: 'cardNumber', value: 'Ingresa un número de tarjeta válido.' },
  'E302':	{ key: 'securityCode', value: 'Revisa el código de seguridad.' },
  '316': { key: 'cardholderName', value: 'Ingresa un nombre válido.' },
  '322': { key: 'docType', value: 'El tipo de documento es inválido.' },
  '323': { key: 'cardholder.document.subtype', value: 'Revisa tu documento.' },
  '324': { key: 'docNumber', value: 'El documento es inválido.' },
  '325': { key: 'cardExpirationMonth', value: 'El mes es inválido.' },
  '326': { key: 'cardExpirationYear', value: 'El año es inválido.' },
};
