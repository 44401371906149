import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Alert from 'react-bootstrap/Alert';
import StepProgressBar from '../../../components/UI/StepProgressBar/StepProgressBar';
import Title from '../../../components/Landing/Title/Title';
import Plans from '../../../components/UI/Landing/Plans/Plans';
import PlanFree from '../../../components/Landing/Planes/PlanFree/PlanFree';
import PlanBimestral from '../../../components/Landing/Planes/PlanBimestral/PlanBimestral';
import PlanAnnual from '../../../components/Landing/Planes/PlanAnual/PlanAual';
import Button from '../../../components/UI/Button/Button';
import PayPal from '../../../components/Register/PayPal/PayPal';
import Oxxo from '../../../components/Register/Oxxo/Oxxo';
import CreditCard from '../../../components/Register/CreditCard/CreditCard';
import Copyright from '../../../components/Landing/Copyright/Copyright';
import * as actions from '../../../actions';
import * as helpers from '../../../helpers';
import logotipo from '../../../assets/images/logotipo.png';
import styles from './Plans.module.scss';

const PlansPage = () => {
  const dispatch = useDispatch();
  const [plan, setPlan] = React.useState(0);
  const [activeKey, setActiveKey] = React.useState('0');
  const registeredUser = useSelector((state) => state.auth.registeredUser);
  const isRegisteringFreePlan = useSelector((state) => state.auth.isRegisteringFreePlan);
  const isValidRegisteredUser = useSelector((state) => state.auth.isValidRegisteredUser);
  const errors = useSelector((state) => state.auth.errors);

  const onBackPlans = () => {
    setActiveKey('0');
    setPlan(0);
  };

  const onSelectFreePlan = () => {
    dispatch(actions.registerFreePlan(registeredUser));
  };

  const onSelectPremiumPlan = (selectedPlan) => {
    window.scrollTo(0, 0);
    setActiveKey('1');
    setPlan(selectedPlan);
  };

  React.useEffect(() => {
    if (!helpers.isObject(errors) && !helpers.isNull(errors)) {
      window.scrollTo(0, 0);
    }
  }, [errors]);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      dispatch(actions.resetRegisteredUser());
    };

    // eslint-disable-next-line
  }, []);
  
  if (!isValidRegisteredUser) {
    return <Redirect to="/registrarse" />
  }

  return (
    <div className={styles.PlansPage}>
      <Navbar sticky="top" expand="lg" className={styles.Navbar}>
        <Container>
          <Link className={classNames('navbar-brand', styles.Logo)} to="/">
            <img src={logotipo} className={styles.Image} alt="Logotipo de Por si no te vuelvo a ver"/>
          </Link>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col>
            <h3 className={styles.Title}>Ya casi terminamos de crear tu cuenta</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <StepProgressBar className={styles.StepProgressBar} activeKey={activeKey}>
              <StepProgressBar.Step eventKey="0">1. Selecciona tu plan</StepProgressBar.Step>
              <StepProgressBar.Step eventKey="1">2. Selecciona tu método de pago</StepProgressBar.Step>
            </StepProgressBar>
          </Col>
        </Row>
      </Container>
      {(activeKey === '0') && (
        <Container>
          <Row>
            <Col>
              <Title center text="Selecciona tu plan" />
            </Col>
          </Row>
          {!helpers.isObject(errors) && !helpers.isNull(errors) && (
            <Row className="justify-content-center">
              <Col>
                <Alert variant="danger">{errors}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Plans className={styles.Plans}>
                <PlanFree>
                  <Button
                    variant="secondary"
                    disabled={isRegisteringFreePlan}
                    onClick={onSelectFreePlan}
                  >
                    {isRegisteringFreePlan ? 'Registrándote…' : 'Empezar'}
                  </Button>
                </PlanFree>
                <PlanAnnual>
                  <Button variant="light" onClick={() => onSelectPremiumPlan(3)}>Empezar</Button>
                </PlanAnnual>
                <PlanBimestral>
                  <Button variant="secondary" onClick={() => onSelectPremiumPlan(2)}>Empezar</Button>
                </PlanBimestral>
              </Plans>
            </Col>
          </Row>
        </Container>
      )}
      {(activeKey === '1') && (
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <Title center text="Selecciona tu método de pago" />
            </Col>
          </Row>
          {!helpers.isObject(errors) && !helpers.isNull(errors) && (
            <Row className="justify-content-center">
              <Col md={8}>
                <Alert variant="danger">{errors}</Alert>
              </Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <Col md={8}>
              <PayPal plan={plan} registeredUser={registeredUser} />
              <CreditCard plan={plan} registeredUser={registeredUser} />
              <Oxxo plan={plan} registeredUser={registeredUser} />
              <hr className={styles.Line} />
              <Button variant="primary" ghost onClick={onBackPlans}>Regresar</Button>
            </Col>
          </Row>
        </Container>
      )}
      <Copyright className={styles.Copyright} />
    </div>
  );
};

export default PlansPage;
