import React from 'react';
import * as helpers from '../helpers';

function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);

  React.useEffect(() => {
    function updateSize() {
      const { width, height } = helpers.getWindowSize();

      setSize([width, height]);
    }

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return size;
};

export default useWindowSize;
