import * as types from '../actions/types/mascotas';

const defaultMascota = {
  id: null,
  nombre: '',
  especie: '',
  cartilla_vacunacion_digital: '',
  cartilla_vacunacion_fisica: '',
  indicaciones: '',
  veterinario: '',
  telefono: ''
};

const initialState = {
  mascota: {...defaultMascota},
  mascotas: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let mascotas = [];

  switch (action.type) {
    case types.GET_MASCOTAS_INIT:
      return {
        ...state,
        mascota: {...defaultMascota},
        mascotas: [],
        isGetting: true,
        errors: null
      };
    case types.GET_MASCOTAS_DONE:
      return {
        ...state,
        mascotas: [...action.mascotas],
        isGetting: false
      };
    case types.GET_MASCOTAS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_MASCOTA_INPUT:
      return {
        ...state,
        mascota: {
          ...state.mascota,
          [action.attribute]: action.value
        }
      };
    case types.RESET_MASCOTA_INPUTS:
      return {
        ...state,
        mascota: {...defaultMascota},
        errors: null
      };
    case types.SAVE_MASCOTA_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_MASCOTA_DONE:
      return {
        ...state,
        mascota: {...defaultMascota},
        mascotas: [
          ...state.mascotas,
          action.mascota
        ],
        isSaving: false
      };
    case types.SAVE_MASCOTA_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.EDIT_MASCOTA:
      return {
        ...state,
        mascota: {...action.mascota}
      };
    case types.UPDATE_MASCOTA_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_MASCOTA_DONE:
      mascotas = state.mascotas.map((mascota) => {
        if (mascota.id === action.mascota.id) {
          return action.mascota;
        }

        return mascota;
      });

      return {
        ...state,
        mascota: {...defaultMascota},
        mascotas,
        isUpdating: false
      };
    case types.UPDATE_MASCOTA_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_MASCOTA_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      }
    case types.DELETE_MASCOTA_DONE:
      mascotas = state.mascotas.filter((mascota) => mascota.id !== action.id);

      return {
        ...state,
        mascotas,
        isDeleting: false
      };
    case types.DELETE_MASCOTA_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
