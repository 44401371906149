import React from 'react';

const IconBox = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="m156 370c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z" />
	  <path d="m450.14 190 58.929-58.929c2.479-2.479 3.478-6.075 2.635-9.477-0.843-3.403-3.407-6.115-6.757-7.149l-143.5-44.29c-12.936-41.581-51.384-70.154-95.451-70.154-40.964 0-77.6 24.965-92.763 62.625l-166.21 51.828c-3.341 1.042-5.895 3.756-6.732 7.155-0.837 3.398 0.163 6.988 2.638 9.463l60.479 60.479-38.144 63.575c-1.561 2.601-1.856 5.77-0.804 8.615s3.339 5.058 6.217 6.017l55.323 18.44v153.8c0 4.168 2.586 7.899 6.489 9.363 33.827 12.684 159.51 59.822 160.19 60.061 1.984 0.699 4.219 0.794 6.398 0.089 0.029-0.01 0.059-0.016 0.088-0.026l180-60c4.083-1.361 6.838-5.183 6.838-9.487v-157.92l58.998-18.54c3.336-1.048 5.882-3.762 6.715-7.158 0.832-3.396-0.169-6.98-2.641-9.453l-58.928-58.929zm-84.144-89.999c0-2.541-0.107-5.076-0.298-7.603l117.49 36.265-49.897 49.896-81.528-27.173c9.232-15.431 14.229-33.237 14.229-51.385zm-175.67-26.028c11.101-32.282 41.512-53.972 75.673-53.972 36.801 0 68.719 24.892 77.618 60.532 1.581 6.333 2.382 12.883 2.382 19.468 0 18.574-6.164 35.983-17.828 50.352-15.277 18.842-37.938 29.648-62.172 29.648-26.196 0-50.767-12.858-65.733-34.404-9.333-13.406-14.267-29.174-14.267-45.596 0-8.931 1.455-17.688 4.327-26.028zm-161.56 54.651 138.3-43.124c-0.694 4.778-1.065 9.618-1.065 14.5 0 16.101 3.814 31.696 11.084 45.767l-98.383 32.792-49.936-49.935zm20.063 126.1 31.62-52.702 160.56 53.521-31.619 52.702c-17.145-5.715-142.26-47.42-160.56-53.521zm197.17 232.84-140-52.5v-140.21c110.15 36.522 104.77 35.407 107.84 35.407 3.438 0 6.73-1.779 8.577-4.856l23.585-39.311v201.47zm10-248.11-148.38-49.458 80.794-26.93c18.91 23.247 47.377 36.929 77.582 36.929 27.942 0 54.203-11.499 73.092-31.759l65.284 21.759-148.38 49.459zm170 195.33-160 53.333v-209.62l35.451 44.31c2.579 3.223 6.872 4.529 10.807 3.293l113.74-35.743v144.43zm-113.21-129.82-40.024-50.026 160.53-53.51 49.964 49.964-170.47 53.572z" />
	  <path d="m219.51 392.2-22.24-8.34c-5.171-1.938-10.935 0.681-12.875 5.852-1.939 5.172 0.681 10.936 5.852 12.875l22.24 8.34c5.182 1.942 10.939-0.69 12.875-5.852 1.939-5.171-0.681-10.936-5.852-12.875z" />
	  <path d="m219.51 434.92-60-22.5c-5.171-1.939-10.935 0.681-12.875 5.852s0.681 10.936 5.852 12.875l60 22.5c1.157 0.434 2.343 0.64 3.51 0.64 4.049 0 7.859-2.477 9.365-6.492 1.94-5.172-0.68-10.936-5.852-12.875z" />
	  <path d="m323.07 62.93c-3.905-3.906-10.237-3.905-14.143 0l-52.929 52.93-12.929-12.929c-3.905-3.905-10.237-3.905-14.143 0s-3.905 10.237 0 14.143l20.001 19.999c3.905 3.905 10.237 3.905 14.143 0l60-60c3.905-3.905 3.905-10.237 0-14.143z" />
	</svg>
);

export default IconBox;
