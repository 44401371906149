import * as types from '../../actions/types/patrimonio/adeudos';

const defaultAdeudo = {
  id: null,
  tipo: false,
  cuenta_contrato: '',
  institucion_persona: '',
  fecha_otorgamiento: '',
  monto_original: '',
  plazo: '',
  documento_fisico: '',
  indicaciones: ''
};

const initialState = {
  adeudo: {...defaultAdeudo},
  adeudos: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let adeudos = [];

  switch (action.type) {
    case types.GET_ADEUDOS_INIT:
      return {
        ...state,
        adeudo: {...defaultAdeudo},
        adeudos: [],
        isGetting: true,
        errors: null
      };
    case types.GET_ADEUDOS_DONE:
      return {
        ...state,
        adeudos: [...action.adeudos],
        isGetting: false
      };
    case types.GET_ADEUDOS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_ADEUDO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_ADEUDO_INPUTS:
      return {
        ...state,
        adeudo: {...defaultAdeudo},
        errors: null
      };
    case types.SAVE_ADEUDO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_ADEUDO_DONE:
      return {
        ...state,
        adeudo: {...defaultAdeudo},
        adeudos: [
          ...state.adeudos,
          action.adeudo
        ],
        isSaving: false
      };
    case types.SAVE_ADEUDO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_ADEUDO_INPUTS:
      return {
        ...state,
        adeudo: {...action.adeudo}
      };
    case types.UPDATE_ADEUDO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_ADEUDO_DONE:
      adeudos = state.adeudos.map((adeudo) => {
        if (adeudo.id === action.adeudo.id) {
          return action.adeudo;
        }

        return adeudo;
      });

      return {
        ...state,
        adeudo: {...defaultAdeudo},
        adeudos,
        isUpdating: false
      };
    case types.UPDATE_ADEUDO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_ADEUDO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_ADEUDO_DONE:
      adeudos = state.adeudos.filter((adeudo) => adeudo.id !== action.id);

      return {
        ...state,
        adeudos,
        isDeleting: false
      };
    case types.DELETE_ADEUDO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
