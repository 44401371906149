export const GET_VIDA_ARTIFICIAL_INIT = 'GET_VIDA_ARTIFICIAL_INIT';
export const GET_VIDA_ARTIFICIAL_DONE = 'GET_VIDA_ARTIFICIAL_DONE';
export const GET_VIDA_ARTIFICIAL_FAIL = 'GET_VIDA_ARTIFICIAL_FAIL';

export const CHANGE_VIDA_ARTIFICIAL_INPUT = 'CHANGE_VIDA_ARTIFICIAL_INPUT';

export const SAVE_VIDA_ARTIFICIAL_INIT = 'SAVE_VIDA_ARTIFICIAL_INIT';
export const SAVE_VIDA_ARTIFICIAL_DONE = 'SAVE_VIDA_ARTIFICIAL_DONE';
export const SAVE_VIDA_ARTIFICIAL_FAIL = 'SAVE_VIDA_ARTIFICIAL_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
