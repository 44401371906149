export const GET_DONACION_ORGANOS_INIT = 'GET_DONACION_ORGANOS_INIT';
export const GET_DONACION_ORGANOS_DONE = 'GET_DONACION_ORGANOS_DONE';
export const GET_DONACION_ORGANOS_FAIL = 'GET_DONACION_ORGANOS_FAIL';

export const CHANGE_DONACION_ORGANOS_INPUT = 'CHANGE_DONACION_ORGANOS_INPUT';

export const SAVE_DONACION_ORGANOS_INIT = 'SAVE_DONACION_ORGANOS_INIT';
export const SAVE_DONACION_ORGANOS_DONE = 'SAVE_DONACION_ORGANOS_DONE';
export const SAVE_DONACION_ORGANOS_FAIL = 'SAVE_DONACION_ORGANOS_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
