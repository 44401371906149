import api from '../api';
import * as creators from './creators/register';
import * as helpers from '../helpers';

export const storeRegisteredUser = (registeredUser) => {
  return async (dispatch) => {
    dispatch(creators.storeRegisteredUserStart());

    try {
      const response = await api.post('register/validate-registered-user', registeredUser);
      
      return dispatch(creators.storeRegisteredUserDone(response.data));
    } catch (error) {
      return dispatch(creators.storeRegisteredUserFail(helpers.resolveErrors(error.response)));
    }
  };
};

export const resetRegisteredUser = () => {
  return (dispatch) => {
    dispatch(creators.resetRegisteredUser());
  };
};

export const registerFreePlan = (data) => {
  return async (dispatch) => {
    dispatch(creators.registerFreePlanStart());

    try {
      const response = await api.post('register/free-plan', data);
      const { token, user, expiresIn } = response.data;

      helpers.setSessionInLocalStorage(token, user, expiresIn);

      return dispatch(creators.registerFreePlanDone(token, user));
    } catch (error) {
      return dispatch(creators.registerFreePlanFail(helpers.resolveErrors(error.response)));
    }
  };
};

export const paypalCreateOrder = (planId) => {
  return async (dispatch) => {
    dispatch(creators.paypalCreateOrderStart());

    try {
      const response = await api.post('register/paypal-create-order', { planId });

      return response;
    } catch (error) {
      return dispatch(creators.paypalCreateOrderFail(helpers.resolveErrors(error.response)));
    }
  };
};

export const paypalApproveOrder = (orderId, data) => {
  return async (dispatch) => {
    dispatch(creators.paypalApproveOrderStart());

    try {
      const response = await api.post(`register/paypal-approve-order/${orderId}`, data);
      const { token, user, expiresIn } = response.data;

      helpers.setSessionInLocalStorage(token, user, expiresIn);

      return dispatch(creators.paypalApproveOrderDone(token, user));
    } catch (error) {
      return dispatch(creators.paypalApproveOrderFail(helpers.resolveErrors(error.response)));
    }
  };
};

export const creditCardPayOrder = (data) => {
  return async (dispatch) => {
    dispatch(creators.creditCardPayOrderStart());
    
    try {
      const response = await api.post('register/credit-card', data);
      const { token, user, expiresIn } = response.data;

      helpers.setSessionInLocalStorage(token, user, expiresIn);

      return dispatch(creators.creditCardPayOrderDone(token, user));
    } catch (error) {
      return dispatch(creators.creditCardPayOrderFail(helpers.resolveErrors(error.response)));
    }
  }
};

export const oxxoPayOrder = (data) => {
  return async (dispatch) => {
    dispatch(creators.oxxoPayOrderStart());

    try {
      const response = await api.post('register/oxxo', data);
      const { transaction_details } = response.data;

      return dispatch(creators.oxxoPayOrderDone(transaction_details.external_resource_url));
    } catch (error) {
      return dispatch(creators.oxxoPayOrderFail(helpers.resolveErrors(error.response)));
    }
  };
};
