import * as types from '../../actions/types/empleo-pension/datos-empleo';

const defaultEmpleo = {
  empresa: '',
  puesto: '',
  numero_empleado: '',
  jefe_directo: '',
  telefono: '',
  enlace_rh: '',
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  contrato_digital: '',
  contrato_fisico: '',
  recibo_pago_digital: '',
  recibo_pago_fisico: '',
  indicaciones: '',
  prestaciones: []
};

const defaultPrestacion = {
  id: null,
  descripcion: ''
};

const initialState = {
  empleo: {...defaultEmpleo},
  prestaciones: [],
  prestacion: {...defaultPrestacion},
  isGetting: false,
  isSaving: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let prestaciones = [];

  switch (action.type) {
    case types.GET_DATOS_EMPLEO_INIT:
      return {
        ...state,
        empleo: {...defaultEmpleo},
        prestaciones: [],
        prestacion: {...defaultPrestacion},
        isGetting: true,
        errors: null
      };
    case types.GET_DATOS_EMPLEO_DONE:
      prestaciones = action.data.empleo ? action.data.empleo.prestaciones.map(({ id, pivot: { descripcion } }) => {
        return { id, descripcion };
      }) : [];

      return {
        ...state,
        empleo: {
          ...state.empleo,
          ...action.data.empleo,
          prestaciones
        },
        prestaciones: [...action.data.prestaciones],
        isGetting: false
      };
    case types.GET_DATOS_EMPLEO_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_DATOS_EMPLEO_INPUT:
      return {
        ...state,
        empleo: {
          ...state.empleo,
          [action.attribute]: action.value
        }
      };
    case types.CHANGE_PRESTACION_INPUT:
      prestaciones = state.empleo.prestaciones.some(({ id }) => {
          return id === action.prestacion.id;
        }) ? state.empleo.prestaciones.filter(({ id }) => {
          return id !== action.prestacion.id;
        }) : [...state.empleo.prestaciones, action.prestacion].map(({ id }) => {
          return { ...defaultPrestacion, id };
        });

      return {
        ...state,
        empleo: {
          ...state.empleo,
          prestaciones
        }
      };
    case types.CHANGE_DESCRIPCION_INPUT:
      prestaciones = state.empleo.prestaciones.map((prestacion) => {
        if (prestacion.id !== 7) {
          return prestacion;
        }

        return {
          ...prestacion,
          descripcion: action.value
        };
      });

      return {
        ...state,
        empleo: {
          ...state.empleo,
          prestaciones
        }
      };
    case types.SAVE_DATOS_EMPLEO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_DATOS_EMPLEO_DONE:
      return {
        ...state,
        empleo: {
          ...state.empleo,
          ...action.empleo
        },
        isSaving: false
      };
    case types.SAVE_DATOS_EMPLEO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
