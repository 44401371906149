export const GET_SEGURIDADES_SOCIALES_INIT = 'GET_SEGURIDADES_SOCIALES_INIT';
export const GET_SEGURIDADES_SOCIALES_DONE = 'GET_SEGURIDADES_SOCIALES_DONE';
export const GET_SEGURIDADES_SOCIALES_FAIL = 'GET_SEGURIDADES_SOCIALES_FAIL';

export const CHANGE_SEGURIDAD_SOCIAL_INPUT = 'CHANGE_SEGURIDAD_SOCIAL_INPUT';

export const RESET_SEGURIDAD_SOCIAL_INPUTS = 'RESET_SEGURIDAD_SOCIAL_INPUTS';

export const SAVE_SEGURIDAD_SOCIAL_INIT = 'SAVE_SEGURIDAD_SOCIAL_INIT';
export const SAVE_SEGURIDAD_SOCIAL_DONE = 'SAVE_SEGURIDAD_SOCIAL_DONE';
export const SAVE_SEGURIDAD_SOCIAL_FAIL = 'SAVE_SEGURIDAD_SOCIAL_FAIL';

export const EDIT_SEGURIDAD_SOCIAL = 'EDIT_SEGURIDAD_SOCIAL';

export const UPDATE_SEGURIDAD_SOCIAL_INIT = 'UPDATE_SEGURIDAD_SOCIAL_INIT';
export const UPDATE_SEGURIDAD_SOCIAL_DONE = 'UPDATE_SEGURIDAD_SOCIAL_DONE';
export const UPDATE_SEGURIDAD_SOCIAL_FAIL = 'UPDATE_SEGURIDAD_SOCIAL_FAIL';

export const DELETE_SEGURIDAD_SOCIAL_INIT = 'DELETE_SEGURIDAD_SOCIAL_INIT';
export const DELETE_SEGURIDAD_SOCIAL_DONE = 'DELETE_SEGURIDAD_SOCIAL_DONE';
export const DELETE_SEGURIDAD_SOCIAL_FAIL = 'DELETE_SEGURIDAD_SOCIAL_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
