import { combineReducers } from 'redux';
import authReducer from './auth';
import menuPrincipalReducer from './menu-principal';
import datosPersonalesReducer from './datos-personales-custodios-red-familiar/datos-personales';
import custodiosReducer from './datos-personales-custodios-red-familiar/custodios';
import redFamiliarReducer from './datos-personales-custodios-red-familiar/red-familiar';
import asesoresContactosReducer from './asesores-contactos';
import polizasSegurosReducer from './polizas-seguros';
import inversionesReducer from './patrimonio/inversiones';
import adeudosReducer from './patrimonio/adeudos';
import vehiculosReducer from './patrimonio/vehiculos';
import bienesInmueblesReducer from './patrimonio/bienes-inmuebles';
import accionesNegociosReducer from './patrimonio/acciones-negocios';
import bienesValoresSentimentalesReducer from './patrimonio/bienes-valores-sentimentales';
import otrosPatrimoniosReducer from './patrimonio/otros-patrimonios';
import datosEmpleoReducer from './empleo-pension/datos-empleo';
import seguridadSocialReducer from './empleo-pension/seguridad-social';
import planPensionReducer from './empleo-pension/plan-pension';
import datosTestamentoReducer from './ultimos-deseos/datos-testamento';
import donacionOrganosReducer from './ultimos-deseos/donacion-organos';
import vidaArtificialReducer from './ultimos-deseos/vida-artificial';
import secuestroDesaparicionReducer from './ultimos-deseos/secuestro-desaparicion';
import restosReducer from './ultimos-deseos/restos';
import mascotasReducer from './mascotas';
import instruccionesParticularesReducer from './instrucciones-particulares';

const rootReducer = combineReducers({
  auth: authReducer,
  menuPrincipal: menuPrincipalReducer,
  datosPersonales: datosPersonalesReducer,
  custodios: custodiosReducer,
  redFamiliar: redFamiliarReducer,
  asesoresContactos: asesoresContactosReducer,
  polizasSeguros: polizasSegurosReducer,
  inversiones: inversionesReducer,
  adeudos: adeudosReducer,
  vehiculos: vehiculosReducer,
  bienesInmuebles: bienesInmueblesReducer,
  accionesNegocios: accionesNegociosReducer,
  bienesValoresSentimentales: bienesValoresSentimentalesReducer,
  otrosPatrimonios: otrosPatrimoniosReducer,
  datosEmpleo: datosEmpleoReducer,
  seguridadSocial: seguridadSocialReducer,
  planPension: planPensionReducer,
  datosTestamento: datosTestamentoReducer,
  donacionOrganos: donacionOrganosReducer,
  vidaArtificial: vidaArtificialReducer,
  secuestroDesaparicion: secuestroDesaparicionReducer,
  restos: restosReducer,
  mascotas: mascotasReducer,
  instruccionesParticulares: instruccionesParticularesReducer
});

export default rootReducer;
