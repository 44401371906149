import * as types from '../actions/types/polizas-seguros';

const defaultPolizaSeguro = {
  id: null,
  tipo: 1,
  marca_tipo: '',
  modelo: '',
  serie: '',
  lugar_registro: '',
  compania: '',
  numero_poliza: '',
  vigencia: '',
  telefono: '',
  suma_asegurada: '',
  beneficios_invalidez: '',
  beneficios_muerte: '',
  poliza_digital: '',
  poliza_fisica: '',
  indicaciones: '',
  beneficiarios: [],
  asesor: {
    nombre_completo: '',
    telefono_oficina: '',
    telefono_particular: '',
    correo_electronico: ''
  }
};

const defaultBeneficiario = {
  nombre_completo: '',
  porcentaje: ''
};

const initialState = {
  polizaSeguro: { ...defaultPolizaSeguro },
  beneficiario: { ...defaultBeneficiario },
  beneficiarios: [],
  polizasSeguros: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let polizasSeguros = [];

  switch (action.type) {
    case types.GET_POLIZAS_SEGUROS_INIT:
      return {
        ...state,
        polizaSeguro: {...defaultPolizaSeguro},
        beneficiario: {...defaultBeneficiario},
        polizasSeguros: [],
        isGetting: true,
        errors: null
      };
    case types.GET_POLIZAS_SEGUROS_DONE:
      return {
        ...state,
        polizasSeguros: [...action.polizasSeguros],
        isGetting: false
      };
    case types.GET_POLIZAS_SEGUROS_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_POLIZA_SEGURO_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.ADD_BENEFICIARIO:
      return {
        ...state,
        polizaSeguro: {
          ...state.polizaSeguro,
          beneficiarios: [
            ...state.polizaSeguro.beneficiarios,
            action.beneficiario
          ]
        }
      };
    case types.RESET_BENEFICIARIO:
      return {
        ...state,
        beneficiario: {...defaultBeneficiario}
      };
    case types.REMOVE_BENEFICIARIO:
      const beneficiarios = [...state.polizaSeguro.beneficiarios];
      beneficiarios.splice(action.index, 1);

      return {
        ...state,
        polizaSeguro: {
          ...state.polizaSeguro,
          beneficiarios
        }
      };
    case types.CHANGE_ASESOR_INPUTS:
      return {
        ...state,
        polizaSeguro: {
          ...state.polizaSeguro,
          asesor: {
            ...state.polizaSeguro.asesor,
            [action.attribute]: action.value
          }
        }
      };
    case types.RESET_POLIZA_SEGURO_INPUTS:
      return {
        ...state,
        polizaSeguro: {...defaultPolizaSeguro},
        beneficiario: {...defaultBeneficiario},
        errors: null
      };
    case types.SAVE_POLIZA_SEGURO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_POLIZA_SEGURO_DONE:
      return {
        ...state,
        polizaSeguro: {...defaultPolizaSeguro},
        beneficiario: {...defaultBeneficiario},
        polizasSeguros: [
          ...state.polizasSeguros,
          action.polizaSeguro
        ],
        isSaving: false
      };
    case types.SAVE_POLIZA_SEGURO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_POLIZA_SEGURO_INPUTS:
      return {
        ...state,
        polizaSeguro: {...action.polizaSeguro}
      };
    case types.UPDATE_POLIZA_SEGURO_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_POLIZA_SEGURO_DONE:
      polizasSeguros = state.polizasSeguros.map((polizaSeguro) => {
        if (polizaSeguro.id === action.polizaSeguro.id) {
          return action.polizaSeguro;
        }

        return polizaSeguro;
      });

      return {
        ...state,
        polizaSeguro: {...defaultPolizaSeguro},
        beneficiario: {...defaultBeneficiario},
        polizasSeguros,
        isUpdating: false
      };
    case types.UPDATE_POLIZA_SEGURO_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_POLIZA_SEGURO_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_POLIZA_SEGURO_DONE:
      polizasSeguros = state.polizasSeguros.filter((polizaSeguro) => polizaSeguro.id !== action.id);

      return {
        ...state,
        polizasSeguros,
        isDeleting: false
      };
    case types.DELETE_POLIZA_SEGURO_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
