import * as types from '../../actions/types/patrimonio/bienes-inmuebles';

const defaultBienInmueble = {
  id: null,
  localizacion: '',
  superficie_terreno: '',
  superficie_construccion: '',
  registro_publico_propiedad: '',
  escrituras_fisica: '',
  indicaciones: ''
};

const initialState = {
  bienInmueble: {...defaultBienInmueble},
  bienesInmuebles: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let bienesInmuebles = [];

  switch (action.type) {
    case types.GET_BIENES_INMUEBLES_INIT:
      return {
        ...state,
        bienInmueble: {...defaultBienInmueble},
        bienesInmuebles: [],
        isGetting: true,
        errors: null
      };
    case types.GET_BIENES_INMUEBLES_DONE:
      return {
        ...state,
        bienesInmuebles: [...action.bienesInmuebles],
        isGetting: false
      };
    case types.GET_BIENES_INMUEBLES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_BIEN_INMUEBLE_INPUTS:
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          [action.attribute]: action.value
        }
      };
    case types.RESET_BIEN_INMUEBLE_INPUTS:
      return {
        ...state,
        bienInmueble: {...defaultBienInmueble},
        errors: null
      };
    case types.SAVE_BIEN_INMUEBLE_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_BIEN_INMUEBLE_DONE:
      return {
        ...state,
        bienInmueble: {...defaultBienInmueble},
        bienesInmuebles: [
          ...state.bienesInmuebles,
          action.bienInmueble
        ],
        isSaving: false
      };
    case types.SAVE_BIEN_INMUEBLE_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.LOAD_BIEN_INMUEBLE_INPUTS:
      return {
        ...state,
        bienInmueble: {...action.bienInmueble}
      };
    case types.UPDATE_BIEN_INMUEBLE_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_BIEN_INMUEBLE_DONE:
      bienesInmuebles = state.bienesInmuebles.map((bienInmueble) => {
        if (bienInmueble.id === action.bienInmueble.id) {
          return action.bienInmueble;
        }

        return bienInmueble;
      });

      return {
        ...state,
        bienInmueble: {...defaultBienInmueble},
        bienesInmuebles,
        isUpdating: false
      };
    case types.UPDATE_BIEN_INMUEBLE_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_BIEN_INMUEBLE_INIT:
      return {
        ...state,
        isDeleting: true,
        error: null
      }
    case types.DELETE_BIEN_INMUEBLE_DONE:
      bienesInmuebles = state.bienesInmuebles.filter((bienInmueble) => bienInmueble.id !== action.id);

      return {
        ...state,
        bienesInmuebles,
        isDeleting: false
      };
    case types.DELETE_BIEN_INMUEBLE_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
