import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as helpers from '../../../helpers';
import styles from './Button.module.scss';

const Button = (props) => {
  const buttonClasses = classNames(
    styles.Button,
    styles[helpers.capitalize(props.variant)],
    [styles[helpers.capitalize(props.align)]],
    'btn',
    'text-uppercase',
    {
      [styles.MarginRight]: props.marginRight,
      [styles.Small]: props.small,
      [styles.Ghost]: props.ghost
    },
    props.className
  );

  if (!!props.link) {
    return (
      <Link className={buttonClasses} to={props.to}>
        {props.children}
      </Link>
    );
  }

  return (
    <button
      type={props.type}
      className={buttonClasses}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button'
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']).isRequired,
  link: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'light', 'danger']).isRequired,
  ghost: PropTypes.bool,
  small: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  marginRight: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string
};

export default Button;
