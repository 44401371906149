import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Plans.module.scss';

const Plans = (props) => {
  const classes = classNames(styles.Plans, props.className);

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

Plans.propTypes = {
  className: PropTypes.string
};

export default Plans;
