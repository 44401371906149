export const GET_DATOS_TESTAMENTO_INIT = 'GET_DATOS_TESTAMENTO_INIT';
export const GET_DATOS_TESTAMENTO_DONE = 'GET_DATOS_TESTAMENTO_DONE';
export const GET_DATOS_TESTAMENTO_FAIL = 'GET_DATOS_TESTAMENTO_FAIL';

export const CHANGE_DATOS_TESTAMENTO_INPUT = 'CHANGE_DATOS_TESTAMENTO_INPUT';

export const SAVE_DATOS_TESTAMENTO_INIT = 'SAVE_DATOS_TESTAMENTO_INIT';
export const SAVE_DATOS_TESTAMENTO_DONE = 'SAVE_DATOS_TESTAMENTO_DONE';
export const SAVE_DATOS_TESTAMENTO_FAIL = 'SAVE_DATOS_TESTAMENTO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
