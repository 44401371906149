export const GET_ASESORES_CONTACTOS_INIT = 'GET_ASESORES_CONTACTOS_INIT';
export const GET_ASESORES_CONTACTOS_DONE = 'GET_ASESORES_CONTACTOS_DONE';
export const GET_ASESORES_CONTACTOS_FAIL = 'GET_ASESORES_CONTACTOS_FAIL';

export const CHANGE_ASESOR_CONTACTO_INPUTS = 'CHANGE_ASESOR_CONTACTO_INPUTS';

export const RESET_ASESOR_CONTACTO_INPUTS = 'RESET_ASESOR_CONTACTO_INPUTS';

export const SAVE_ASESOR_CONTACTO_INIT = 'SAVE_ASESOR_CONTACTO_INIT';
export const SAVE_ASESOR_CONTACTO_DONE = 'SAVE_ASESOR_CONTACTO_DONE';
export const SAVE_ASESOR_CONTACTO_FAIL = 'SAVE_ASESOR_CONTACTO_FAIL';

export const LOAD_ASESOR_CONTACTO_INPUTS = 'LOAD_ASESOR_CONTACTO_INPUTS';

export const UPDATE_ASESOR_CONTACTO_INIT = 'UPDATE_ASESOR_CONTACTO_INIT';
export const UPDATE_ASESOR_CONTACTO_DONE = 'UPDATE_ASESOR_CONTACTO_DONE';
export const UPDATE_ASESOR_CONTACTO_FAIL = 'UPDATE_ASESOR_CONTACTO_FAIL';

export const DELETE_ASESOR_CONTACTO_INIT = 'DELETE_ASESOR_CONTACTO_INIT';
export const DELETE_ASESOR_CONTACTO_DONE = 'DELETE_ASESOR_CONTACTO_DONE';
export const DELETE_ASESOR_CONTACTO_FAIL = 'DELETE_ASESOR_CONTACTO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
