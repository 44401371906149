import * as types from '../../actions/types/empleo-pension/seguridad-social';

const defaultSeguridadSocial = {
  id: null,
  nombre: '',
  numero: '',
  clinica: '',
  telefono: ''
};

const initialState = {
  seguridadSocial: { ...defaultSeguridadSocial },
  seguridadesSociales: [],
  isGetting: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  let seguridadesSociales = [];

  switch (action.type) {
    case types.GET_SEGURIDADES_SOCIALES_INIT:
      return {
        ...state,
        seguridadSocial: {...defaultSeguridadSocial},
        seguridadesSociales: [],
        isGetting: true,
        errors: null
      };
    case types.GET_SEGURIDADES_SOCIALES_DONE:
      return {
        ...state,
        seguridadesSociales: [...action.seguridadesSociales],
        isGetting: false
      };
    case types.GET_SEGURIDADES_SOCIALES_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_SEGURIDAD_SOCIAL_INPUT:
      return {
        ...state,
        seguridadSocial: {
          ...state.seguridadSocial,
          [action.attribute]: action.value
        }
      };
    case types.RESET_SEGURIDAD_SOCIAL_INPUTS:
      return {
        ...state,
        seguridadSocial: {...defaultSeguridadSocial},
        errors: null
      };
    case types.SAVE_SEGURIDAD_SOCIAL_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_SEGURIDAD_SOCIAL_DONE:
      return {
        ...state,
        seguridadSocial: {...defaultSeguridadSocial},
        seguridadesSociales: [
          ...state.seguridadesSociales,
          action.seguridadSocial
        ],
        isSaving: false
      };
    case types.SAVE_SEGURIDAD_SOCIAL_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.EDIT_SEGURIDAD_SOCIAL:
      return {
        ...state,
        seguridadSocial: {...action.seguridadSocial}
      };
    case types.UPDATE_SEGURIDAD_SOCIAL_INIT:
      return {
        ...state,
        isUpdating: true,
        errors: null
      };
    case types.UPDATE_SEGURIDAD_SOCIAL_DONE:
      seguridadesSociales = state.seguridadesSociales.map((seguridadSocial) => {
        if (seguridadSocial.id === action.seguridadSocial.id) {
          return action.seguridadSocial;
        }

        return seguridadSocial;
      });

      return {
        ...state,
        seguridadSocial: {...defaultSeguridadSocial},
        seguridadesSociales,
        isUpdating: false
      };
    case types.UPDATE_SEGURIDAD_SOCIAL_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.errors
      };
    case types.DELETE_SEGURIDAD_SOCIAL_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      }
    case types.DELETE_SEGURIDAD_SOCIAL_DONE:
      seguridadesSociales = state.seguridadesSociales.filter((seguridadSocial) => seguridadSocial.id !== action.id);

      return {
        ...state,
        seguridadesSociales,
        isDeleting: false
      };
    case types.DELETE_SEGURIDAD_SOCIAL_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
