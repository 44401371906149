import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '../../../hooks/useWindowSize';
import galeria01 from '../../../assets/images/galeria-01.jpg';
import galeria02 from '../../../assets/images/galeria-02.jpg';
import galeria03 from '../../../assets/images/galeria-03.jpg';
import Slider from '../../UI/Landing/Slider/Slider';

const Galeria = (props) => {
  const [, windowHeight] = useWindowSize();
  const navbarHeight = props.navbarHeight;
  const minimumNabvarHeight = 100;
  const maximumWindowHeight = 620;
  const minimumWindowHeight = 540;
  const currentNabvarHeight = (navbarHeight > 0) ? navbarHeight : minimumNabvarHeight;
  const currentWindowHeight = (windowHeight - currentNabvarHeight);

  const galeriaStyles = {
    marginTop: `-${currentNabvarHeight}px`,
    paddingTop: `${currentNabvarHeight}px`
  };

  let sliderHeight = 0;

  if ((currentWindowHeight > minimumWindowHeight) && (currentWindowHeight < maximumWindowHeight)) {
    sliderHeight = currentWindowHeight;
  } else if (currentWindowHeight < minimumWindowHeight) {
    sliderHeight = minimumWindowHeight;
  } else if (currentWindowHeight > maximumWindowHeight) {
    sliderHeight = maximumWindowHeight;
  }

  const sliderStyles = {
    height: `${sliderHeight}px`
  };
  
  return (
    <div style={galeriaStyles}>
      <Slider controls={false} style={sliderStyles}>
        <Slider.Item>
          <Slider.Image src={galeria01} />
          <Slider.Caption>
            <Slider.Title>Por si no te vuelvo a ver</Slider.Title>
            <Slider.Paragraph>Un legado de amor y seguridad para sus seres queridos por si algún día llegas a faltar.</Slider.Paragraph>
            <Slider.Paragraph>Deja a nuestro resguardo y envío póstumo toda la información digital que a tus seres queridos desees compartir, tal como tu biografía, pensamientos, relación de bienes, documentos y recuerdos importantes para ti.</Slider.Paragraph>
            <Slider.Link to="/registrarse">Crea tu cuenta gratis</Slider.Link>
          </Slider.Caption>
        </Slider.Item>
        <Slider.Item>
          <Slider.Image src={galeria02} />
          <Slider.Caption>
            <Slider.Title>Por si no te vuelvo a ver</Slider.Title>
            <Slider.Paragraph>Un legado de amor y seguridad para sus seres queridos por si algún día llegas a faltar.</Slider.Paragraph>
            <Slider.Paragraph>Deja a nuestro resguardo y envío póstumo toda la información digital que a tus seres queridos desees compartir, tal como tu biografía, pensamientos, relación de bienes, documentos y recuerdos importantes para ti.</Slider.Paragraph>
            <Slider.Link to="/registrarse">Crea tu cuenta gratis</Slider.Link>
          </Slider.Caption>
        </Slider.Item>
        <Slider.Item>
          <Slider.Image src={galeria03} />
          <Slider.Caption>
            <Slider.Title>Por si no te vuelvo a ver</Slider.Title>
            <Slider.Paragraph>Un legado de amor y seguridad para sus seres queridos por si algún día llegas a faltar.</Slider.Paragraph>
            <Slider.Paragraph>Deja a nuestro resguardo y envío póstumo toda la información digital que a tus seres queridos desees compartir, tal como tu biografía, pensamientos, relación de bienes, documentos y recuerdos importantes para ti.</Slider.Paragraph>
            <Slider.Link to="/registrarse">Crea tu cuenta gratis</Slider.Link>
          </Slider.Caption>
        </Slider.Item>
      </Slider>
    </div>
  );
};

Galeria.propTypes = {
  navbarHeight: PropTypes.number.isRequired
};

export default Galeria;
