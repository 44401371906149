export const GET_BIENES_VALORES_SENTIMENTALES_INIT = 'GET_BIENES_VALORES_SENTIMENTALES_INIT';
export const GET_BIENES_VALORES_SENTIMENTALES_DONE = 'GET_BIENES_VALORES_SENTIMENTALES_DONE';
export const GET_BIENES_VALORES_SENTIMENTALES_FAIL = 'GET_BIENES_VALORES_SENTIMENTALES_FAIL';

export const CHANGE_BIEN_VALOR_SENTIMENTAL_INPUTS = 'CHANGE_BIEN_VALOR_SENTIMENTAL_INPUTS';

export const RESET_BIEN_VALOR_SENTIMENTAL_INPUTS = 'RESET_BIEN_VALOR_SENTIMENTAL_INPUTS';

export const SAVE_BIEN_VALOR_SENTIMENTAL_INIT = 'SAVE_BIEN_VALOR_SENTIMENTAL_INIT';
export const SAVE_BIEN_VALOR_SENTIMENTAL_DONE = 'SAVE_BIEN_VALOR_SENTIMENTAL_DONE';
export const SAVE_BIEN_VALOR_SENTIMENTAL_FAIL = 'SAVE_BIEN_VALOR_SENTIMENTAL_FAIL';

export const LOAD_BIEN_VALOR_SENTIMENTAL_INPUTS = 'LOAD_BIEN_VALOR_SENTIMENTAL_INPUTS';

export const UPDATE_BIEN_VALOR_SENTIMENTAL_INIT = 'UPDATE_BIEN_VALOR_SENTIMENTAL_INIT';
export const UPDATE_BIEN_VALOR_SENTIMENTAL_DONE = 'UPDATE_BIEN_VALOR_SENTIMENTAL_DONE';
export const UPDATE_BIEN_VALOR_SENTIMENTAL_FAIL = 'UPDATE_BIEN_VALOR_SENTIMENTAL_FAIL';

export const DELETE_BIEN_VALOR_SENTIMENTAL_INIT = 'DELETE_BIEN_VALOR_SENTIMENTAL_INIT';
export const DELETE_BIEN_VALOR_SENTIMENTAL_DONE = 'DELETE_BIEN_VALOR_SENTIMENTAL_DONE';
export const DELETE_BIEN_VALOR_SENTIMENTAL_FAIL = 'DELETE_BIEN_VALOR_SENTIMENTAL_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
