import React from 'react';

const IconPencil = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="m498.17 80.934c-2.389-4.979-8.363-7.079-13.341-4.692-4.979 2.389-7.08 8.362-4.692 13.341 22.07 46.014 12.69 101.17-23.335 137.32l-170.6-170.6c36.157-36.029 91.314-45.408 137.33-23.332 4.979 2.389 10.952 0.289 13.341-4.691 2.389-4.979 0.289-10.953-4.691-13.341-53.72-25.772-118.13-14.77-160.28 27.377l-238.2 232.1c-1.572 1.53-2.624 3.606-2.928 5.779l-30.691 219.27c-0.43 3.344 0.721 6.701 3.254 8.956 3.081 2.743 6.741 2.618 10.579 2.076l215.07-30.346c2.684-0.379 4.7-1.851 6.56-3.711l235.24-235.24c42.142-42.143 53.146-106.55 27.38-160.27zm-262.57 367.17c-3.579-14.326-10.368-25.947-20.294-34.695-8.876-7.823-18.869-12.019-27.595-14.246l206.54-206.54 48.417 48.417zm-204.34-26.321c30.108 3.875 54.026 27.792 57.9 57.9l-67.415 9.514zm2.681-19.819 15.38-109.88c7.745 1.029 19.63 4.026 29.136 12.657 10.126 9.195 15.666 23.048 16.476 41.169 0.029 5.846 5.297 10.514 11.097 9.86 0.195-0.021 19.59-1.878 33.758 10.371 9.942 8.597 15.375 22.419 16.147 41.083 0.231 5.595 5.255 9.965 10.791 9.555 0.208-0.013 20.718-1.396 35.506 11.762 8.471 7.537 13.677 18.622 15.554 32.995l-108.82 15.355c-5.118-38.941-36.068-69.86-75.024-74.927zm118.7-51.144c-8.262-7.071-17.447-10.904-25.604-12.946l206.23-206.23 46.84 46.84-207.03 207.03c-3.585-14.51-10.419-26.133-20.43-34.7zm-60.743-60.886c-9.41-8.544-20.14-13.141-29.382-15.6l209.77-203.66 46.834 46.834-207.09 207.09c-3.57-14.127-10.305-25.738-20.133-34.661z" />
    <path d="m454.49 53.217c2.068 5.044 8.027 7.485 13.05 5.41 5.033-2.078 7.504-8.029 5.41-13.06-2.095-5.031-8.01-7.492-13.05-5.41-5.029 2.077-7.503 8.036-5.41 13.06z" />
  </svg>
);

export default IconPencil;
