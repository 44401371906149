import React from 'react';
import { Link } from 'react-router-dom';
import logotipo from '../../../assets/images/logotipo.png';
import TerminosCondiciones from '../../Landing/TerminosCondiciones/TerminosCodiciones';
import PoliticaPrivacidad from '../../Landing/PoliticaPrivacidad/PoliticaPrivacidad';
import styles from './Layout.module.scss';

const Layout = (props) => {
  const [showTerminosCondiciones, setShowTerminosCondiciones] = React.useState(false);
  const [showPoliticaPrivacidad, setShowPoliticaPrivacidad] = React.useState(false);

  const onShowTerminosCondiciones = (event) => {
    event.preventDefault();

    setShowTerminosCondiciones(true);
  };
  
  const onHideTerminosCondiciones = () => setShowTerminosCondiciones(false);

  const onShowPoliticaPrivacidad = (event) => {
    event.preventDefault();

    setShowPoliticaPrivacidad(true);
  };

  const onHidePoliticaPrivacidad = () => setShowPoliticaPrivacidad(false);

  return (
    <React.Fragment>
      <div className={styles.Register}>
        <div className={styles.Left}>
          <Link className={styles.Logo} to="/">
            <img src={logotipo} className={styles.Image} alt="Logotipo de Por si no te vuelvo a ver" />
          </Link>
          <div className={styles.Content}>
            {props.children(styles)}
          </div>
          <div className={styles.Copyright}>&copy;2020 Por si no te vuelvo a ver. Todos los derechos reservados. <a href="!#" onClick={onShowPoliticaPrivacidad}>Aviso de privacidad</a>. <a href="!#" onClick={onShowTerminosCondiciones}>Términos y condiciones</a>.</div>
        </div>
        <div className={styles.Right}>
          <div className={styles.Content}>
            <h1 className={styles.Title}>Por si no te vuelvo a ver</h1>
            <div className={styles.Laptop}>
              <iframe src="https://www.youtube.com/embed/ScMzIvxBSi4?wmode=opaque&amp;amp;enablejsapi=1&amp;amp;modestbranding=1&amp;amp;showinfo=0&amp;amp;rel=0&amp;amp;controls=0" className={styles.Video} width="584" height="366" frameBorder="0" allowFullScreen title="Video"></iframe>
            </div>
          </div>
        </div>
      </div>
      <TerminosCondiciones show={showTerminosCondiciones} onHide={onHideTerminosCondiciones} />
      <PoliticaPrivacidad show={showPoliticaPrivacidad} onHide={onHidePoliticaPrivacidad} />
    </React.Fragment>
  );
};

export default Layout;
