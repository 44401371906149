export const GET_OTROS_PATRIMONIOS_INIT = 'GET_OTROS_PATRIMONIOS_INIT';
export const GET_OTROS_PATRIMONIOS_DONE = 'GET_OTROS_PATRIMONIOS_DONE';
export const GET_OTROS_PATRIMONIOS_FAIL = 'GET_OTROS_PATRIMONIOS_FAIL';

export const CHANGE_OTRO_PATRIMONIO_INPUTS = 'CHANGE_OTRO_PATRIMONIO_INPUTS';

export const RESET_OTRO_PATRIMONIO_INPUTS = 'RESET_OTRO_PATRIMONIO_INPUTS';

export const SAVE_OTRO_PATRIMONIO_INIT = 'SAVE_OTRO_PATRIMONIO_INIT';
export const SAVE_OTRO_PATRIMONIO_DONE = 'SAVE_OTRO_PATRIMONIO_DONE';
export const SAVE_OTRO_PATRIMONIO_FAIL = 'SAVE_OTRO_PATRIMONIO_FAIL';

export const LOAD_OTRO_PATRIMONIO_INPUTS = 'LOAD_OTRO_PATRIMONIO_INPUTS';

export const UPDATE_OTRO_PATRIMONIO_INIT = 'UPDATE_OTRO_PATRIMONIO_INIT';
export const UPDATE_OTRO_PATRIMONIO_DONE = 'UPDATE_OTRO_PATRIMONIO_DONE';
export const UPDATE_OTRO_PATRIMONIO_FAIL = 'UPDATE_OTRO_PATRIMONIO_FAIL';

export const DELETE_OTRO_PATRIMONIO_INIT = 'DELETE_OTRO_PATRIMONIO_INIT';
export const DELETE_OTRO_PATRIMONIO_DONE = 'DELETE_OTRO_PATRIMONIO_DONE';
export const DELETE_OTRO_PATRIMONIO_FAIL = 'DELETE_OTRO_PATRIMONIO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
