import * as types from '../../actions/types/ultimos-deseos/datos-testamento';

const defaultTestamento = {
  notario: '',
  numero_notaria: '',
  telefono: '',
  fecha_extencion: '',
  numero_escritura: '',
  calle_numero: '',
  colonia: '',
  codigo_postal: '',
  municipio: '',
  estado: '',
  documento_digital: '',
  documento_fisico: '',
  indicaciones: ''
};

const initialState = {
  testamento: {...defaultTestamento},
  isGetting: false,
  isSaving: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DATOS_TESTAMENTO_INIT:
      return {
        ...state,
        testamento: {...defaultTestamento},
        isGetting: true,
        errors: null
      };
    case types.GET_DATOS_TESTAMENTO_DONE:
      return {
        ...state,
        testamento: {
          ...state.testamento,
          ...action.testamento
        },
        isGetting: false
      };
    case types.GET_DATOS_TESTAMENTO_FAIL:
      return {
        ...state,
        isGetting: false,
        errors: action.errors
      };
    case types.CHANGE_DATOS_TESTAMENTO_INPUT:
      return {
        ...state,
        testamento: {
          ...state.testamento,
          [action.attribute]: action.value
        }
      };
    case types.SAVE_DATOS_TESTAMENTO_INIT:
      return {
        ...state,
        isSaving: true,
        errors: null
      };
    case types.SAVE_DATOS_TESTAMENTO_DONE:
      return {
        ...state,
        testamento: {
          ...state.testamento,
          ...action.testamento
        },
        isSaving: false
      };
    case types.SAVE_DATOS_TESTAMENTO_FAIL:
      return {
        ...state,
        isSaving: false,
        errors: action.errors
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default reducer;
