import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ColorPrimary.module.scss';

const ColorPrimary = (props) => {
  const classes = classNames(styles.ColorPrimary, props.className);

  return (
    <span className={classes}>
      {props.children}
    </span>
  );
};

ColorPrimary.propTypes = {
  className: PropTypes.string
};

export default ColorPrimary;
