import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Dialog from '../../UI/Landing/Dialog/Dialog';

const TerminosCondiciones = (props) => {

  return ReactDOM.createPortal(
    <Dialog
      title="Términos y condiciones"
      show={props.show}
      onHide={props.onHide}
    >
      <p>El presente aviso legal regula los Términos y Condiciones de Uso de la página web <strong>www.porsinotevuelvoaver.com</strong> de la que es responsable Alberto Molina Diz, persona física con actividad empresarial con domicilio en Calle 20 número 33 en San Jose Vista Hermosa. Codigo Postal 72190 en Puebla, Puebla, México.</p>
      <p>Alberto Molina Diz (La administración), cumple con la legislación mexicana establecida tanto en el Código de Comercio como en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y le corresponde velar por su cumplimiento.</p>
      <p>La utilización de la página web le atribuye la condición de usuario de la misma e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en los presentes Términos y Condiciones de Uso, así como de la política de privacidad publicados, por la Administración en el momento en el que el Usuario acceda a la web.</p>
      <h4>Descripción</h4>
      <p><strong>www.porsinotevuelvoaver.com</strong> es una web dedicada a la administración información relacionada a la última voluntad y deseos del usuario, así como poner a su disposición herramientas y servicios para la creación de un legado digital.</p>
      <p>El objetivo de los servicios de Www.porsinotevuelvoaver.com es el almacenamiento digital seguro y discreto de las últimas voluntades en caso de una posible incapacidad mental o su defunción. Esto significa que, tras el fallecimiento del usuario registrado, Mi Legado Digital hará llegar a los custodios designados por el usuario dichas voluntades y deseos, las cuales fueron previamente depositadas en su cuenta conforme a las instrucciones del usuario.</p>
      <p>De manera especial, se puede convenir un acuerdo para la ejecución de dicha notificación en caso de incapacidad de obrar en vida del usuario.</p>
      <p><strong>www.porsinotevuelvoaver.com</strong> puede servir de enlace hacia agencias de seguros, abogados o notarios, pero en ningún caso es responsable de las ofertas reflejadas en la web, incidencias o cualquier problema derivado de estos servicios.</p>
      <p>La voluntad y deseos virtuales reflejados por el usuario en <strong>www.porsinotevuelvoaver.com</strong> no sustituye, bajo ningún concepto, a un testamento avalado ante notario.</p>
      <p>Estos derechos y responsabilidades tienen su origen en los principios que promueve <strong>www.porsinotevuelvoaver.com</strong> y rigen la relación entre los usuarios y todos aquellos que interactúen con dicho sitio web.</p>
      <h4>Registro</h4>
      <p>Para poder hacer uso de los servicios de <strong>www.porsinotevuelvoaver.com</strong> es necesario que se registre como usuario, para lo que le solicitaremos su correo electrónico. Posteriormente podrá incluir datos adicionales.</p>
      <p><strong>El Usuario</strong> es el único responsable de la información contenida en su cuenta y de la veracidad de esta, no creará una cuenta para otras personas sin su autorización.</p>
      <p>No creará más de un perfil personal.</p>
      <p>Mantendrá la información de contacto exacta y actualizada. Si selecciona un nombre de usuario para tu cuenta, nos reservamos el derecho a eliminarlo o reclamarlo si lo consideramos oportuno.</p>
      <h4>Moneda para las operaciones</h4>
      <p>Las monedas que se utilizarán en las operaciones que se realicen a través de la página aparecen indicadas en pesos mexicanos o en dólares de los Estados Unidos de América, según se indique, o en la moneda que al efecto se señale para cada producto o servicio.</p>
      <h4>Edad mínima para ser usuario</h4>
      <p>La utilización de los servicios ofrecidos en <strong>www.porsinotevuelvoaver.com</strong> requiere que el Usuario haya cumplido, al menos, dieciocho años de edad.</p>
      <p>Bajo ningún concepto y bajo ninguna excepción estará permitida la participación en cualquiera de los servicios ofrecidos por <strong>www.porsinotevuelvoaver.com</strong> a los menores de dieciocho años, ni siquiera utilizando la cuenta de un Usuario registrado correctamente.</p>
      <h4>Propiedad intelectual e industrial</h4>
      <p>Todas las marcas, nombres comerciales, logotipos, signos distintivos de cualquier clase que aparecen en <strong>www.porsinotevuelvoaver.com</strong> son propiedad de la empresa o de terceros, sin que pueda entenderse que el uso y la utilización de sus servicios atribuya al Usuario derecho alguno sobre las citadas marcas, nombres comerciales, logotipos y signos distintivos.</p>
      <p>Asimismo, los contenidos que sean propiedad intelectual de la empresa o de terceros, no podrán entenderse cedidos al Usuario, en virtud de lo establecido en estas Condiciones Generales ninguno de los derechos de explotación que existen o puedan existir sobre dichos contenidos más allá de lo estrictamente necesario para el correcto uso de los servicios de <strong>www.porsinotevuelvoaver.com</strong>. Los únicos derechos de propiedad intelectual que se le conceden al Usuario son los inherentes al uso de los servicios propios de <strong>www.porsinotevuelvoaver.com</strong> quedando prohibida en todo caso la utilización comercial.</p>
      <h4>Exclusión de garantías y límite de responsabilidad</h4>
      <p>La empresa no garantiza la disponibilidad y continuidad del funcionamiento de <strong>www.porsinotevuelvoaver.com</strong> y de sus servicios.</p>
      <p>La administración procurará advertir con suficiente antelación de las interrupciones que pudieran suceder en el funcionamiento de <strong>www.porsinotevuelvoaver.com</strong> y de sus servicios, siempre que ello sea posible.</p>
      <p>La empresa excluye, con toda la extensión permitida por el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento de <strong>www.porsinotevuelvoaver.com</strong> y de sus servicios.</p>
      <p>Exclusión de garantías y de responsabilidad por el funcionamiento del software, en relación a los contenidos, posibilidad de virus y eficacia de las medidas de seguridad.</p>
      <p>La empresa no controla ni garantiza la ausencia de virus ni de otros elementos en los servicios prestados por terceros a través de <strong>www.porsinotevuelvoaver.com</strong> que puedan producir alteraciones en el sistema informático del Usuario.</p>
      <p>La empresa excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus o a la presencia de otros elementos lesivos en los servicios prestados por terceros a través de <strong>www.porsinotevuelvoaver.com</strong> que puedan producir alteraciones en el sistema informático de los usuarios.</p>
      <p>La Administración no garantiza la licitud, fiabilidad de los servicios prestados por terceros a través de <strong>www.porsinotevuelvoaver.com</strong>. La Administración excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a los servicios prestados por terceros a través de <strong>www.porsinotevuelvoaver.com</strong>.</p>
      <h4>Terminación</h4>
      <p>La prestación de los servicios que la Administración proporciona a través de <strong>www.porsinotevuelvoaver.com</strong>, en principio, <strong>tiene una duración indefinida</strong>. No obstante, La administración está autorizada para dar por terminada o suspender la prestación de sus servicios en cualquier momento, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes Condiciones Particulares. Si se detecta que algún usuario comete cualquier tipo de infracción grave o se provoca cualquier tipo de riesgo a <strong>www.porsinotevuelvoaver.com</strong> exponiéndonos legalmente, se emprenderán las medidas oportunas entre las que se encuentra dejar de proporcionar acceso a <strong>www.porsinotevuelvoaver.com</strong> y notificándose por correo electrónico o mediante mensaje en tu próximo acceso a la web. También podrá eliminar su cuenta y toda la información disponible en cualquier momento.</p>
      <p>El Usuario registrado puede revocar su contrato con <strong>www.porsinotevuelvoaver.com</strong> en cualquier momento sin señalar motivo alguno.</p>
      <p>Se pasará a cuenta gratuita cualquier cuenta de usuario premium y que no haga efectiva la renovación a la finalización de dicho plazo, pudiendo recuperar toda la información introducida hasta la fecha si regulariza el pago dentro de un plazo de tres meses desde el vencimiento de la anualidad, tras el cual la administración estará en su derecho de eliminar cualquier dato que no incluya la cuenta gratuita.</p>
      <p>En el momento en que el Usuario registrado proceda con la baja de su cuenta supondrá perder todos los derechos que había adquirido y su plan elegido, sin cabida a indemnización alguna, dado que la cobertura de los servicios contratados permanecen en vigor solo durante el alta de dicha cuenta.</p>
      <h4>Obligaciones</h4>
      <p>Como usuario, cuando acceda a <strong>www.porsinotevuelvoaver.com</strong> o lo use deberá apegarse a todas las leyes y reglamentos aplicables.</p>
      <p>El usuario tiene la obligación de utilizar la Web de manera cuidadosa y correcta, comprometiéndose a no utilizar la plataforma para realizar actividades contrarias a la ley, la moral o con fines o efectos ilícitos, prohibidos o que afecten el derecho e intereses de terceros. <strong>www.porsinotevuelvoaver.com</strong> se compromete para que los contenidos no tengan carácter discriminatorio, racista, xenofobo o que inciden de manera directa o indirecta a la violencia, poniendo el correo <strong>contacto@porsinotevuelvoaver.com</strong> para denunciar contenido inapropiado.</p>
      <p>Esta Declaración no otorga derechos de beneficiario a ningún tercero. Cuando acceda a Mi Herencia Digital o lo use deberá cumplir todas las leyes aplicables.</p>
      <h4>Denuncia por actividades ilícitas</h4>
      <p>En el caso de que cualquier usuario, visitante, o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de cualquier contenido, deberá ponerse en contacto con <strong>www.porsinotevuelvoaver.com</strong> indicando siempre:</p>
      <ul>
        <li>Los datos personales del reclamante (nombre, dirección, número de teléfono y dirección de correo electrónico).</li>
        <li>La supuesta actividad ilícita llevada a cabo en <strong>www.porsinotevuelvoaver.com</strong> y su motivación que revelen dicho carácter ilícito.</li>
      </ul>
      <h4>Normativa aplicada</h4>
      <p>Las presentes Condiciones Generales se rigen por la legislación mexicana. En caso de controversia derivada de la aplicación o interpretación de los presentes términos y condiciones, las partes se someterán al procedimiento de conciliación de la Procuraduría Federal del Consumidor o a la jurisdicción de los tribunales de la Ciudad de México, Distrito Federal, renunciando a cualquiera otra jurisdicción o fuero, presente o futuro, que les corresponda por razón de su domicilio o vecindad o que llegasen a adquirir por cualquier otra causa.</p>
    </Dialog>,
    document.getElementById('modal')
  );
};

TerminosCondiciones.defaultProps = {
  show: false
};

TerminosCondiciones.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default TerminosCondiciones;
