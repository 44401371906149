import api from '../api';
import * as creators from './creators/auth';
import * as helpers from '../helpers';

export const login = (data) => {
  return async (dispatch) => {
    dispatch(creators.loginStart());

    try {
      const response = await api.post('login', data);
      const { token, user, expiresIn } = response.data;

      helpers.setSessionInLocalStorage(token, user, expiresIn);

      return dispatch(creators.loginDone(token, user));
    } catch (error) {
      return dispatch(creators.loginFail(helpers.resolveErrors(error.response)));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');

      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('expirationDate');

      dispatch(creators.logout());
      await api.post(`logout?token=${token}`);
    } catch (error) {}
  };
};

const checkIfAuthenticationExpired = () => {
  const expirationDate = new Date(localStorage.getItem('expirationDate'));

  return expirationDate <= new Date();
};

export const checkIfUserIsAuthenticated = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');

    if (!token) {
      logout();
    } else {
      const user = JSON.parse(localStorage.getItem('user'));

      if (checkIfAuthenticationExpired()) {
        logout();
      } else {
        dispatch(creators.loginDone(token, user));
      }
    }
  };
};
