export const GET_RESTOS_INIT = 'GET_RESTOS_INIT';
export const GET_RESTOS_DONE = 'GET_RESTOS_DONE';
export const GET_RESTOS_FAIL = 'GET_RESTOS_FAIL';

export const CHANGE_RESTO_INPUT = 'CHANGE_RESTO_INPUT';

export const CHANGE_SEPULTADO_INPUT = 'CHANGE_SEPULTADO_INPUT';

export const CHANGE_CREMADO_INPUT = 'CHANGE_CREMADO_INPUT';

export const SAVE_RESTOS_INIT = 'SAVE_RESTOS_INIT';
export const SAVE_RESTOS_DONE = 'SAVE_RESTOS_DONE';
export const SAVE_RESTOS_FAIL = 'SAVE_RESTOS_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
