import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import scriptLoader from 'react-async-script-loader';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from '../../UI/Button/Button';
import * as actions from '../../../actions';
import { creditCardErrors } from '../../../constants';
import styles from './CreditCard.module.scss';

const CreditCard = (props) => {
  const defaultInvalidInputs = { cardholderName: '', cardNumber: '', cardExpirationMonth: '', cardExpirationYear: '', securityCode: '' };
  const mercadoPago = React.useRef(null);
  const cardholderName = React.useRef(null);
  const [invalidInputs, setInvalidInputs] = React.useState(defaultInvalidInputs);
  const [paymentMethodId, setPaymentMethodId] = React.useState('');
  const isPayingWithCreditCard = useSelector((state) => state.auth.isPayingWithCreditCard);
  const dispatch = useDispatch();

  const onOpen = () => {
    setTimeout(() => cardholderName.current.focus(), 100);
  };

  const onChangeCardNumberInput = (event) => {
    const value = event.target.value;
    const bin = value.substring(0, 6);

    setTimeout(() => {
      if (bin.length >= 6) {
        mercadoPago.current.getPaymentMethod({ bin }, (status, response) => {
          if (status === 200 && !!response) {
            setPaymentMethodId(response[0].id);
            setInvalidInputs((prevState) => ({ ...prevState, cardNumber: '' }));
          } else {
            setInvalidInputs((prevState) => ({ ...prevState, cardNumber: 'Ingresa un número de tarjeta válido.' }));
          }
        });
      }
    }, 100);
  };

  const onPay = (event) => {
    event.preventDefault();

    mercadoPago.current.clearSession();

    mercadoPago.current.createToken(event.target, async (status, response) => {
      setInvalidInputs((prevState) => ({ ...prevState, ...defaultInvalidInputs }));

      if (status !== 200 && status !== 201) {
        const errors = response.cause.reduce((errors, error) => {
          const errorObject = creditCardErrors[error.code]; 

          if (!errorObject) {
            return errors;
          }

          const errorKey = errorObject.key;

          if (errors.hasOwnProperty(errorKey)) {
            return errors;
          }
          
          const errorValue = errorObject.value;

          errors[errorKey] = errorValue;

          return errors;
        }, {});

        setInvalidInputs((prevState) => ({ ...prevState, ...errors }));
      } else {
        dispatch(actions.creditCardPayOrder({ token: response.id, paymentMethodId, ...props.registeredUser, plan_id: props.plan }));
      }
    });
  };

  React.useEffect(() => {
    const { isScriptLoaded, isScriptLoadSucceed } = props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      mercadoPago.current = window.Mercadopago;
      mercadoPago.current.setPublishableKey('TEST-e482872b-4910-4a01-b677-10df65dd0701');
    }

    // eslint-disable-next-line
  }, [props.isScriptLoaded, props.isScriptLoadSucceed]);

  return (
    <div className={styles.CreditCard}>
      <Accordion className={styles.Accordion}>
        <Card className={styles.Card}>
          <Accordion.Collapse eventKey="0">
            <Card.Body className={styles.Body}>
              <Form onSubmit={onPay} className={styles.Form} autoComplete="off">
                <Form.Group controlId="cardholderName">
                  <Form.Label className={styles.Label}>Nombre del titular</Form.Label>
                  <Form.Control
                    type="text"
                    className={styles.Control}
                    data-checkout="cardholderName"
                    disabled={isPayingWithCreditCard}
                    ref={cardholderName}
                  />
                  {invalidInputs.cardholderName && (
                    <Form.Control.Feedback type="invalid" className={styles.Feedback}>
                      {invalidInputs.cardholderName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="cardNumber">
                  <Form.Label className={styles.Label}>Número de tarjeta</Form.Label>
                  <Form.Control
                    type="text"
                    className={styles.Control}
                    data-checkout="cardNumber"
                    disabled={isPayingWithCreditCard}
                    onChange={onChangeCardNumberInput}
                    placeholder="#### #### #### ####"
                  />
                  {invalidInputs.cardNumber && (
                    <Form.Control.Feedback type="invalid" className={styles.Feedback}>
                      {invalidInputs.cardNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="cardExpirationMonth" className={styles.Label}>Vencimiento</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        id="cardExpirationMonth"
                        className={styles.Control}
                        data-checkout="cardExpirationMonth"
                        disabled={isPayingWithCreditCard}
                        autoComplete="off"
                        placeholder="Mes"
                      />
                      <Form.Control
                        type="text"
                        id="cardExpirationYear"
                        className={styles.Control}
                        data-checkout="cardExpirationYear"
                        disabled={isPayingWithCreditCard}
                        autoComplete="off"
                        placeholder="Año"
                      />
                    </InputGroup>
                    <Form.Row>
                      <Col>
                        {invalidInputs.cardExpirationMonth && (
                          <Form.Control.Feedback type="invalid" className={styles.Feedback}>
                            {invalidInputs.cardExpirationMonth}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                      <Col>
                        {invalidInputs.cardExpirationYear && (
                          <Form.Control.Feedback type="invalid" className={styles.Feedback}>
                            {invalidInputs.cardExpirationYear}
                          </Form.Control.Feedback>
                        )}
                      </Col>
                    </Form.Row>
                  </Form.Group>
                  <Form.Group as={Col} controlId="securityCode">
                    <Form.Label className={styles.Label}>Código de seguridad</Form.Label>
                    <Form.Control
                      type="text"
                      className={styles.Control}
                      data-checkout="securityCode"
                      disabled={isPayingWithCreditCard}
                      autoComplete="off"
                      placeholder="###"
                    />
                    {invalidInputs.securityCode && (
                      <Form.Control.Feedback type="invalid" className={styles.Feedback}>
                        {invalidInputs.securityCode}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
                <Button
                  type="submit"
                  variant="primary"
                  align="right"
                  disabled={isPayingWithCreditCard}
                >
                  {isPayingWithCreditCard ? 'Registrándote…' : 'Pagar'}
                </Button>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
          <Accordion.Toggle as={Card.Header} className={styles.Header} onClick={onOpen} eventKey="0">
            Pagar con tarjeta de crédito o débito
          </Accordion.Toggle>
        </Card>
      </Accordion>
    </div>
  );
};

CreditCard.propTypes = {
  plan: PropTypes.number
};

export default scriptLoader('https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js')(CreditCard);
