import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Landing from './pages/Landing/Landing';
import Register from './pages/Register/Register';
import Plans from './pages/Register/Plans/Plans';
import Login from './pages/Login/Login';
import RouteGuard from './utilities/RouteGuard';
import * as actions from './actions';

const MenuPrincipal = React.lazy(() => import('./pages/MenuPrincipal/MenuPrincipal'));
const DatosPersonalesCustodiosRedFamiliar = React.lazy(() => import('./pages/DatosPersonalesCustodiosRedFamiliar/DatosPersonalesCustodiosRedFamiliar'));
const AsesoresContactos = React.lazy(() => import('./pages/AsesoresContactos/AsesoresContactos'));
const PolizasSeguros = React.lazy(() => import('./pages/PolizasSeguros/PolizasSeguros'));
const Patrimonio = React.lazy(() => import('./pages/Patrimonio/Patrimonio'));
const EmpleoPension = React.lazy(() => import('./pages/EmpleoPension/EmpleoPension'));
const UltimosDeseos = React.lazy(() => import('./pages/UltimosDeseos/UltimosDeseos'));
const Mascotas = React.lazy(() => import('./pages/Mascotas/Mascotas'));
const InstruccionesParticulares = React.lazy(() => import('./pages/InstruccionesParticulares/InstruccionesParticulares'));

function App() {
  const timeout = React.useRef(null);
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      const timeRemaining = (expirationDate - new Date());

      function checkIfSessionExpired() {
        clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
          dispatch(actions.logout());
          clearTimeout(timeout.current);
        }, timeRemaining);
      }

      checkIfSessionExpired();
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);

  React.useEffect(() => {
    dispatch(actions.checkIfUserIsAuthenticated());

    return () => {
      clearTimeout(timeout.current);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      {isAuthenticated ? (
        <React.Suspense fallback={<div>Cargando...</div>}>
          <Switch>
            <RouteGuard exact path="/instrucciones-particulares" passes={true}>
              <InstruccionesParticulares />
            </RouteGuard>
            <RouteGuard exact path="/mascotas" passes={true}>
              <Mascotas />
            </RouteGuard>
            <RouteGuard exact path="/ultimos-deseos" passes={true}>
              <UltimosDeseos />
            </RouteGuard>
            <RouteGuard exact path="/empleo-y-pension" passes={true}>
              <EmpleoPension />
            </RouteGuard>
            <RouteGuard exact path="/patrimonio" passes={true}>
              <Patrimonio />
            </RouteGuard>
            <RouteGuard exact path="/polizas-de-seguros" passes={true}>
              <PolizasSeguros />
            </RouteGuard>
            <RouteGuard exact path="/asesores-y-contactos" passes={true}>
              <AsesoresContactos />
            </RouteGuard>
            <RouteGuard exact path="/datos-personales-custodios-y-red-familiar" passes={true}>
              <DatosPersonalesCustodiosRedFamiliar />
            </RouteGuard>
            <RouteGuard exact path="/" passes={true}>
              <MenuPrincipal />
            </RouteGuard>
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      ) : (
        <Switch>
          <Route exact path="/iniciar-sesion" component={Login} />
          <Route exact path="/planes" component={Plans} />
          <Route exact path="/registrarse" component={Register} />
          <Route exact path="/" component={Landing} />
          <Redirect to="/" />
        </Switch>
      )}
    </Router>
  );
}

export default App;
