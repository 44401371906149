export const GET_FAMILIARES_INIT = 'GET_FAMILIARES_INIT';
export const GET_FAMILIARES_DONE = 'GET_FAMILIARES_DONE';
export const GET_FAMILIARES_FAIL = 'GET_FAMILIARES_FAIL';

export const CHANGE_FAMILIAR_INPUT = 'CHANGE_FAMILIAR_INPUT';

export const RESET_FAMILIAR_INPUTS = 'RESET_FAMILIAR_INPUTS';

export const SAVE_FAMILIAR_INIT = 'SAVE_FAMILIAR_INIT';
export const SAVE_FAMILIAR_DONE = 'SAVE_FAMILIAR_DONE';
export const SAVE_FAMILIAR_FAIL = 'SAVE_FAMILIAR_FAIL';

export const EDIT_FAMILIAR = 'EDIT_FAMILIAR';

export const UPDATE_FAMILIAR_INIT = 'UPDATE_FAMILIAR_INIT';
export const UPDATE_FAMILIAR_DONE = 'UPDATE_FAMILIAR_DONE';
export const UPDATE_FAMILIAR_FAIL = 'UPDATE_FAMILIAR_FAIL';

export const DELETE_FAMILIAR_INIT = 'DELETE_FAMILIAR_INIT';
export const DELETE_FAMILIAR_DONE = 'DELETE_FAMILIAR_DONE';
export const DELETE_FAMILIAR_FAIL = 'DELETE_FAMILIAR_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
