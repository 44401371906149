import * as types from '../types/auth';

export const storeRegisteredUserStart = () => {
  return {
    type: types.STORE_REGISTERED_USER_START
  };
};

export const storeRegisteredUserDone = (registeredUser) => {
  return {
    type: types.STORE_REGISTERED_USER_DONE,
    registeredUser
  };
};

export const storeRegisteredUserFail = (errors) => {
  return {
    type: types.STORE_REGISTERED_USER_FAIL,
    errors
  };
};

export const resetRegisteredUser = () => {
  return {
    type: types.RESET_REGISTERED_USER
  };
};

export const registerFreePlanStart = () => {
  return {
    type: types.REGISTER_FREE_PLAN_START
  };
};

export const registerFreePlanDone = (token, user) => {
  return {
    type: types.REGISTER_FREE_PLAN_DONE,
    token,
    user
  };
};

export const registerFreePlanFail = (errors, errorStatus) => {
  return {
    type: types.REGISTER_FREE_PLAN_FAIL,
    errors,
    errorStatus
  };
};

export const paypalCreateOrderStart = () => {
  return {
    type: types.PAYPAL_CREATE_ORDER_START
  };
};

export const paypalCreateOrderFail = (errors) => {
  return {
    type: types.PAYPAL_CREATE_ORDER_FAIL,
    errors
  };
};

export const paypalApproveOrderStart = () => {
  return {
    type: types.PAYPAL_APPROVE_ORDER_START
  };
};

export const paypalApproveOrderDone = (token, user) => {
  return {
    type: types.PAYPAL_APPROVE_ORDER_DONE,
    token,
    user
  };
};

export const paypalApproveOrderFail = (errors) => {
  return {
    type: types.PAYPAL_APPROVE_ORDER_FAIL,
    errors
  };
};

export const creditCardPayOrderStart = () => {
  return {
    type: types.CREDIT_CARD_PAY_ORDER_START
  };
};

export const creditCardPayOrderDone = (token, user) => {
  return {
    type: types.CREDIT_CARD_PAY_ORDER_DONE,
    token,
    user
  };
};

export const creditCardPayOrderFail = (errors) => {
  return {
    type: types.CREDIT_CARD_PAY_ORDER_FAIL,
    errors
  };
};

export const oxxoPayOrderStart = () => {
  return {
    type: types.OXXO_PAY_ORDER_START
  };
};

export const oxxoPayOrderDone = (ticket) => {
  return {
    type: types.OXXO_PAY_ORDER_DONE,
    ticket
  };
};

export const oxxoPayOrderFail = (errors) => {
  return {
    type: types.OXXO_PAY_ORDER_FAIL,
    errors
  };
};
