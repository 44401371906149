import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import styles from './Dialog.module.scss';

const Dialog = (props) => (
  <Modal
    className={styles.Dialog}
    size="lg"
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header className={styles.Header} closeButton>
      <Modal.Title className={styles.Title}>
        {props.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={styles.Body}>
      {props.children}
    </Modal.Body>
  </Modal>
);

Dialog.defaultProps = {
  show: false,
};

Dialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default Dialog;
