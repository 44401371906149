import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isBrowser } from 'react-device-detect';
import Spinner from 'react-bootstrap/Spinner';
import styles from './Overlap.module.scss';

const Overlap = (props) => (
  <div className={styles.Overlap}>
   {props.children}
  </div>
);

const Front = (props) => {
  const [image, isLoading] = useImage(props);

  return (
    <div className={styles.Front}>
      {isLoading && <Spinner animation="border" className={styles.Spinner} />}
      {image}
    </div>
  );
};

Front.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

const Back = (props) => {
  const [image,] = useImage(props);

  const backClasses = classNames(styles.Back, {
    [styles.Translate]: isBrowser
  });

  return (
    <div className={backClasses}>
      {image}
    </div>
  );
};

Back.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

const useImage = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const classes = classNames(styles.Image, {
    [styles.Visible]: !isLoading
  });
  
  const onLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  React.useEffect(() => {
    setIsLoading(true);
  }, [props.src]);

  return [<img src={props.src} className={classes} onLoad={onLoad} alt={props.alt} />, isLoading]
};

Overlap.Front = Front;
Overlap.Back = Back;

export default Overlap;
