export const GET_DATOS_EMPLEO_INIT = 'GET_DATOS_EMPLEO_INIT';
export const GET_DATOS_EMPLEO_DONE = 'GET_DATOS_EMPLEO_DONE';
export const GET_DATOS_EMPLEO_FAIL = 'GET_DATOS_EMPLEO_FAIL';

export const CHANGE_DATOS_EMPLEO_INPUT = 'CHANGE_DATOS_EMPLEO_INPUT';

export const CHANGE_PRESTACION_INPUT = 'CHANGE_PRESTACION_INPUT';

export const CHANGE_DESCRIPCION_INPUT = 'CHANGE_DESCRIPCION_INPUT';

export const SAVE_DATOS_EMPLEO_INIT = 'SAVE_DATOS_EMPLEO_INIT';
export const SAVE_DATOS_EMPLEO_DONE = 'SAVE_DATOS_EMPLEO_DONE';
export const SAVE_DATOS_EMPLEO_FAIL = 'SAVE_DATOS_EMPLEO_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
