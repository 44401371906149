import * as types from '../types/auth';

export const loginStart = () => {
  return {
    type: types.LOGIN_START
  };
};

export const loginDone = (token, user) => {
  return {
    type: types.LOGIN_DONE,
    token,
    user
  };
};

export const loginFail = (errors) => {
  return {
    type: types.LOGIN_FAIL,
    errors
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};
