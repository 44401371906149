import React from 'react';
import Plan from '../../../UI/Landing/Plan/Plan';
import BreakLine from '../../../UI/BreakLine/BreakLine';

const PlanAnual = (props) => {
  const hasChildren = React.Children.count(props.children) > 0;
  
  return (
    <Plan active>
      <Plan.Type>Servicio Pro<BreakLine /> Oferta</Plan.Type>
      <Plan.Price>$ 899 <Plan.Recurrence>Anual</Plan.Recurrence></Plan.Price>
      <Plan.Description>
        <Plan.Paragraph>Descuento del 30%</Plan.Paragraph>
        <Plan.Paragraph>Guía y biografía</Plan.Paragraph>
        <Plan.Paragraph>Última voluntad</Plan.Paragraph>
        <Plan.Paragraph>Inventario de posesiones</Plan.Paragraph>
        <Plan.Paragraph>Legado personal</Plan.Paragraph>
        <Plan.Paragraph>Asesores y directorio</Plan.Paragraph>
        <Plan.Paragraph>Mensajes póstumos</Plan.Paragraph>
        <Plan.Paragraph>Mascostas</Plan.Paragraph>
        <Plan.Paragraph>Guía de elaboración de testamento</Plan.Paragraph>
      </Plan.Description>
      {hasChildren ? props.children : (<Plan.Link variant="light" to="/registrarse">Empezar</Plan.Link>)}
    </Plan>
  );
};

export default PlanAnual;
