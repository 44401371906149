export const GET_PLANES_PENSIONES_INIT = 'GET_PLANES_PENSIONES_INIT';
export const GET_PLANES_PENSIONES_DONE = 'GET_PLANES_PENSIONES_DONE';
export const GET_PLANES_PENSIONES_FAIL = 'GET_PLANES_PENSIONES_FAIL';

export const CHANGE_PLAN_PENSION_INPUT = 'CHANGE_PLAN_PENSION_INPUT';

export const CHANGE_BENEFICIARIO_INPUT = 'CHANGE_BENEFICIARIO_INPUT';

export const ADD_BENEFICIARIO = 'ADD_BENEFICIARIO';
export const RESET_BENEFICIARIO = 'RESET_BENEFICIARIO';
export const REMOVE_BENEFICIARIO = 'REMOVE_BENEFICIARIO';

export const CHANGE_ASESOR_INPUT = 'CHANGE_ASESOR_INPUT';

export const RESET_PLAN_PENSION_INPUTS = 'RESET_PLAN_PENSION_INPUTS';

export const SAVE_PLAN_PENSION_INIT = 'SAVE_PLAN_PENSION_INIT';
export const SAVE_PLAN_PENSION_DONE = 'SAVE_PLAN_PENSION_DONE';
export const SAVE_PLAN_PENSION_FAIL = 'SAVE_PLAN_PENSION_FAIL';

export const EDIT_PLAN_PENSION = 'EDIT_PLAN_PENSION';

export const UPDATE_PLAN_PENSION_INIT = 'UPDATE_PLAN_PENSION_INIT';
export const UPDATE_PLAN_PENSION_DONE = 'UPDATE_PLAN_PENSION_DONE';
export const UPDATE_PLAN_PENSION_FAIL = 'UPDATE_PLAN_PENSION_FAIL';

export const DELETE_PLAN_PENSION_INIT = 'DELETE_PLAN_PENSION_INIT';
export const DELETE_PLAN_PENSION_DONE = 'DELETE_PLAN_PENSION_DONE';
export const DELETE_PLAN_PENSION_FAIL = 'DELETE_PLAN_PENSION_FAIL';

export const RESET_ERRORS = 'RESET_ERRORS';
